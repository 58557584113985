import React, { useEffect, useState, useCallback } from "react";
import { Table } from "antd";
import { Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  hrCourses,
  hrCourseById,
} from "../../../../../helpers/endpoints/api_endpoints";
import TrainingForm from "../../../Employees/EmployeeTraining/TrainingForm";
import Header from "../../../../layout/Header";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TrainingApproval = () => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [documentLists, setDocumentLists] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDueDate, setSelectedDueDate] = useState(null);

  const fetchCourses = useCallback(() => {
    let endpoint = hrCourses;

    if (selectedDepartment !== null) {
      endpoint += `?department=${selectedDepartment}`;
    }

    if (searchTerm) {
      endpoint +=
        selectedDepartment !== null
          ? `&title__icontains=${searchTerm}`
          : `?title__icontains=${searchTerm}`;
    }

    if (selectedStatus) {
      endpoint +=
        (selectedDepartment !== null || searchTerm ? `&` : `?`) +
        `${selectedStatus}=true`;
    }

    if (selectedDueDate) {
      const formattedDate = selectedDueDate.toISOString().split("T")[0];
      endpoint +=
        (selectedDepartment !== null || searchTerm || selectedStatus
          ? `&`
          : `?`) + `due_date=${formattedDate}`;
    }

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setDocumentLists(response.data);
        setFilteredDocuments(response.data);
        setIsLoading(false);
        console.log("Courses", response.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [selectedDepartment, searchTerm, selectedStatus, selectedDueDate]);

  useEffect(() => {
    fetchCourses();
  }, [
    shouldRefresh,
    selectedDepartment,
    searchTerm,
    selectedStatus,
    selectedDueDate,
    fetchCourses,
  ]);

  const handleShowDetails = (record) => {
    setSelectedRecord(record);
    setFormData(record);
    setShowDetailsModal(true);
  };

  const handleCloseDetails = () => {
    setShowDetailsModal(false);
  };

  const handleCloseAdd = () => {
    setShowAddModal(false);
    setShouldRefresh(!shouldRefresh); // Toggle the shouldRefresh state to trigger re-fetching
  };

  const handleApprove = (recordId) => {
    const originalState = selectedRecord.is_approved;
    const updatedRecord = {
      ...selectedRecord,
      is_approved: true,
      is_approval: false,
    };
    setSelectedRecord(updatedRecord);

    axiosInstance
      .patch(hrCourseById(recordId), { is_approved: true, is_approval: false })
      .then((response) => {
        const updatedList = documentLists.map((item) =>
          item.id === recordId
            ? { ...item, is_approved: true, is_approval: false }
            : item
        );
        setDocumentLists(updatedList);
        setFilteredDocuments(updatedList);
        toast.success("Course approved successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to approve course.");
        setSelectedRecord({ ...selectedRecord, is_approved: originalState });
      });
  };
  const formatDuration = (durationString) => {
    if (!durationString) return "";

    let totalHours = 0;
    let totalMinutes = 0;

    if (durationString.includes(" ")) {
      // Case when the string is like "1 01:00:00"
      const [daysPart, timePart] = durationString.split(" ");
      const days = parseInt(daysPart, 10);
      const [hours, minutes] = timePart.split(":").map(Number);

      totalHours = days * 24 + hours;
      totalMinutes = minutes;
    } else {
      // Case when the string is like "21:00:00"
      const [hours, minutes] = durationString.split(":").map(Number);

      totalHours = hours;
      totalMinutes = minutes;
    }

    return `${totalHours} hours ${totalMinutes} minutes`;
  };

  const handleFilter = (departmentId) => {
    setSelectedDepartment(departmentId);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Course Name",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          {text}
        </div>
      ),
    },
    {
      title: "Requested By",
      dataIndex: ["user_details", "employee_name"],
      key: "user_details",
      render: (text) => <div>{text ? text.substring(0, 20) : ""}</div>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (text) => <div>{text ? text.substring(0, 20) : ""}</div>,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (text) => <div className="table-avatar">{text}</div>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <>
          <Button
            onClick={() => handleShowDetails(record)}
            className="btn btn-primary me-2"
          >
            Show Details
          </Button>
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (record) => (
        <span
          style={{
            padding: "10px",
            color: "#fff",
            backgroundColor: record.is_approved ? "#28a745" : "#ffc107",
          }}
        >
          {record.is_approved ? "Approved" : "Not Approved"}
        </span>
      ),
    },
  ];

  const departments = [
    { id: 1, name: "Human Resources" },
    { id: 2, name: "GACL" },
    { id: 3, name: "Technical" },
    { id: 4, name: "Operations" },
    { id: 5, name: "Marketing" },
    { id: 6, name: "Head Office" },
  ];

  return (
    <>
      <Header />

      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <div className="row mb-4">
            <div className="col-12">
              <h1 className="text-2xl font-bold">Training Course Approval</h1>
            </div>
          </div>
          <div className="row mb-10">
            <div className="col-12 pb-2 d-flex align-items-center">
              {departments.map((department) => (
                <button
                  key={department.id}
                  onClick={() => handleFilter(department.id)}
                  className={
                    selectedDepartment === department.id
                      ? "btn btn-success me-2"
                      : "btn btn-outline-success me-2"
                  }
                >
                  {department.name}
                </button>
              ))}
              <button
                onClick={() => handleFilter(null)}
                className={
                  selectedDepartment === null
                    ? "btn btn-success"
                    : "btn btn-outline-success"
                }
              >
                All
              </button>
              <div className="ms-auto d-inline-block">
                <div className="input-block mb-0 ">
                  <select
                    className="form-select mt-1"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <option value="">Select Status</option>
                    <option value="is_approval">Sent for Approval</option>
                    <option value="is_approved">Approved</option>
                    <option value="is_publish">Published</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-md-3">
                <div className="input-block mb-1 position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Course Name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <i
                    className="fa fa-search position-absolute"
                    style={{
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-md-3">
                <div className="input-block mb-1 position-relative">
                  <DatePicker
                    selected={selectedDueDate}
                    onChange={(date) => setSelectedDueDate(date)}
                    className="form-control"
                    placeholderText="Select Due Date"
                    dateFormat="yyyy/MM/dd"
                  />
                  <i
                    className="fa fa-calendar position-absolute"
                    style={{
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                className="table-striped"
                style={{ overflowX: "auto" }}
                columns={columns}
                dataSource={filteredDocuments}
                rowKey={(record) => record.id}
                loading={isLoading}
              />
            </div>
            <Modal
              show={showDetailsModal}
              onHide={handleCloseDetails}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {selectedRecord && (
                  <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Title</label>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={formData.title || ""}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Platform</label>
                          <input
                            type="text"
                            className="form-control"
                            name="platform"
                            value={formData.platform || ""}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Employee Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="user_details"
                            value={formData.user_details.employee_name || ""}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Description</label>
                          <textarea
                            className="form-control"
                            name="description"
                            value={formData.description || ""}
                            readOnly
                            style={{ minHeight: "70px" }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Cost</label>
                          <input
                            type="number"
                            className="form-control"
                            name="cost"
                            value={formData.cost || ""}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-block mb-3">
                          <Button
                            variant="primary"
                            onClick={() =>
                              window.open(formData.course_link, "_blank")
                            }
                            style={{ marginTop: "30px" }}
                          >
                            Redirect to Course
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Departments</label>
                          <input
                            type="text"
                            className="form-control"
                            name="departments"
                            value={selectedRecord.department_details
                              .map((dept) => dept.name)
                              .join(", ")}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Duration</label>
                          <input
                            type="text"
                            className="form-control"
                            name="duration"
                            value={formatDuration(formData.duration)}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDetails}>
                  Close
                </Button>
                {selectedRecord && (
                  <>
                    <Button
                      variant={
                        selectedRecord.is_approved ? "success" : "primary"
                      }
                      onClick={() => handleApprove(selectedRecord.id)}
                    >
                      {selectedRecord.is_approved ? "Approved" : "Approve"}
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
            <Modal show={showAddModal} onHide={handleCloseAdd} size="xl">
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <TrainingForm insideModal={true} />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAdd}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingApproval;
