import React, { useEffect, useState, useCallback } from "react";
import { Table } from "antd";
import { Button, Modal, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  hrCourses,
  hrCourseById,
  employeeDetails,
} from "../../../../../helpers/endpoints/api_endpoints";
import TrainingForm from "../../../Employees/EmployeeTraining/TrainingForm";

const TeamLeaderList = () => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [documentLists, setDocumentLists] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [employeeID, setEmployeeID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const fetchCourses = useCallback(() => {
    if (!employeeID) return;

    let endpoint = `${hrCourses}?user=${employeeID}`;

    if (searchTerm) {
      endpoint += `&title__icontains=${searchTerm}`;
    }

    if (selectedStatus) {
      if (selectedStatus === "is_read") {
        endpoint += `&is_read=true`;
      } else if (selectedStatus === "is_approved") {
        endpoint += `&is_approved=true&is_read=false&is_publish=true`;
      } else if (selectedStatus === "is_approval") {
        endpoint += `&is_approval=true&is_read=false`;
      } else if (selectedStatus === "is_hr") {
        endpoint += `&is_approval=false&is_read=false&is_approved=false`;
      } else if (selectedStatus === "is_publish") {
        endpoint += `&is_approved=true&is_publish=true`;
      }
    }

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setDocumentLists(response.data);
        setIsLoading(false);
        console.log("Hr Courses", response.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [employeeID, searchTerm, selectedStatus]);

  useEffect(() => {
    fetchCourses();
  }, [shouldRefresh, employeeID, fetchCourses]);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeID(response.data.employment_details[0].id);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const handleShowDetails = (record) => {
    setSelectedRecord(record);
    setFormData(record);
    setShowDetailsModal(true);
  };

  const handleCloseDetails = () => {
    setShowDetailsModal(false);
  };

  const handleShowAdd = () => {
    setShowAddModal(true);
  };

  const handleCloseAdd = () => {
    setShowAddModal(false);
    setShouldRefresh(!shouldRefresh); // Toggle the shouldRefresh state to trigger re-fetching
  };

  const handleDelete = (recordId) => {
    axiosInstance
      .delete(hrCourseById(recordId))
      .then(() => {
        const updatedList = documentLists.filter(
          (item) => item.id !== recordId
        );
        setDocumentLists(updatedList);
        toast.success("Record deleted successfully!");
        setShowDetailsModal(false); // Close the modal after deleting
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to delete the record.");
      });
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Course Name",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          {text}
        </div>
      ),
    },
    {
      title: "Requested By",
      dataIndex: ["user_details", "employee_name"],
      key: "user_details",
      render: (text) => <div>{text ? text.substring(0, 20) : ""}</div>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (text) => <div>{text ? text.substring(0, 20) : ""}</div>,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (text) => <div className="table-avatar">{text}</div>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <>
          <Button
            onClick={() => handleShowDetails(record)}
            className="btn btn-primary me-2"
          >
            Show Details
          </Button>
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        let statusText = "Request Sent";
        let backgroundColor = "#ffc107";

        if (record.is_read) {
          statusText = "Not Approved";
          backgroundColor = "#f44336";
        } else if (record.is_approved && !record.is_publish) {
          statusText = "Course Approved";
          backgroundColor = "#80bfff";
        } else if (record.is_approval) {
          statusText = "Requested for Approval";
          backgroundColor = "#7b6b51";
        } else if (record.is_publish) {
          statusText = "Published";
          backgroundColor = "#4caf50";
        }

        return (
          <span
            style={{
              padding: "10px",
              color: "#fff",
              backgroundColor,
            }}
          >
            {statusText}
          </span>
        );
      },
    },
  ];

  return (
    <div className="row">
      <div className="row mb-10">
        <div className="col-12 pb-2 d-flex align-items-center justify-content-between">
          <div className="col-md-3">
            <div className="input-block mb-1 position-relative">
              <input
                type="text"
                className="form-control"
                placeholder="Search by Course Name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <i
                className="fa fa-search position-absolute"
                style={{
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              ></i>
            </div>
          </div>
          <div className="d-inline-block">
            <div className="input-block mb-0">
              <select
                className="form-select mt-1"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value="is_read">Not Approved</option>
                <option value="is_approved">Approved</option>
                <option value="is_approval">Requested for Approval</option>
                <option value="is_hr">Requested to HR</option>
                <option value="is_publish">Published</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-10">
        <div className="col-12">
          <div
            className="position-absolute"
            style={{ top: "30px", right: "20px" }}
          >
            <button
              type="button"
              className="btn btn-success"
              onClick={handleShowAdd}
            >
              Apply for Course
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={documentLists}
            rowKey={(record) => record.id}
            loading={isLoading}
          />
        </div>
        <Modal show={showDetailsModal} onHide={handleCloseDetails} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedRecord && (
              <Form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={formData.title || ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Platform</label>
                      <input
                        type="text"
                        className="form-control"
                        name="platform"
                        value={formData.platform || ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Employee Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="user_details"
                        value={formData.user_details.employee_name || ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Description</label>
                      <textarea
                        className="form-control"
                        name="description"
                        value={formData.description || ""}
                        readOnly
                        style={{
                          minHeight: "70px",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Cost</label>
                      <input
                        type="number"
                        className="form-control"
                        name="cost"
                        value={formData.cost || ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input-block mb-3">
                      <Button
                        variant="primary"
                        onClick={() =>
                          window.open(formData.course_link, "_blank")
                        }
                        style={{ marginTop: "30px" }}
                      >
                        Redirect to Course
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Departments</label>
                      <input
                        type="text"
                        className="form-control"
                        name="departments"
                        value={selectedRecord.department_details
                          .map((dept) => dept.name)
                          .join(", ")}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDetails}>
              Close
            </Button>
            {selectedRecord && (
              <>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(selectedRecord.id)}
                >
                  Delete
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>
        <Modal show={showAddModal} onHide={handleCloseAdd} size="xl">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <TrainingForm insideModal={true} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAdd}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default TeamLeaderList;
