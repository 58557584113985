export const OperationsideData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Operations Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/operations-dashboard",
        icon: "la la-dashcube",
      },

      {
        menuValue: "Tracks",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-briefcase",
        subMenus: [
          {
            menuValue: "Track Initialization",
            route: "#",
          },
          {
            menuValue: "Track Assigned",
            route: "#",
          },
        ],
      },
    ],
  },
];
