// action - state management

import {
  CLEAR_JOB_APPLICATION_FORM_DATA,
  CLEAR_JOB_POSTING_FORM_DATA,
  CLEAR_JOB_POSTING_FORM_ERRORS,
  POPULATE_JOB_APPLICATION_POSTING_FORM_DATA,
  POPULATE_JOB_POSTING_FORM,
  UPDATE_EMIRATES_NAMES,
  UPDATE_JOB_CATEGORIES,
  UPDATE_JOB_POSTING_FORM_ERRORS,
  UPDATE_SHOW_PAYMENT_MODAL,
  UPDATE_TAGS,
} from "./actions/jobActions";

export const initialState = {
  jobPostingFormData: {
    name: "",
    tags: "",
    emirate_name: "",
    referral_code: "",
    description: "",
  },
  jobPostingFormErrors: {
    name: "",
    tags: "",
    emirate_name: "",
    description: "",
  },
  jobCategories: [],
  emiratesNames: [],
  tags: [],
  jobApplicationFormData: {
    subject: "",
    request_message: "",
    document: [],
  },
  showPaymentModal: false,
};

//-----------------------|| JOBS REDUCER ||-----------------------//

const jobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case POPULATE_JOB_POSTING_FORM: {
      const data = action.payload;
      return { ...state, jobPostingFormData: data };
    }

    case CLEAR_JOB_POSTING_FORM_DATA:
      return {
        ...state,
        jobPostingFormData: {
          ...initialState.jobPostingFormData,
          referral_code: "",
        },
      };

    case UPDATE_JOB_POSTING_FORM_ERRORS: {
      const field = action.field;
      const data = action.payload;
      return {
        ...state,
        jobPostingFormErrors: { ...state.jobPostingFormErrors, [field]: data },
      };
    }

    case CLEAR_JOB_POSTING_FORM_ERRORS:
      return {
        ...state,
        jobPostingFormErrors: initialState.jobPostingFormErrors,
      };

    case POPULATE_JOB_APPLICATION_POSTING_FORM_DATA: {
      const data = action.payload;
      return { ...state, jobApplicationFormData: data };
    }

    case CLEAR_JOB_APPLICATION_FORM_DATA:
      return {
        ...state,
        jobApplicationFormData: initialState.jobApplicationFormData,
      };

    case UPDATE_JOB_CATEGORIES: {
      return { ...state, jobCategories: action.payload };
    }

    case UPDATE_EMIRATES_NAMES: {
      return { ...state, emiratesNames: action.payload };
    }
    case UPDATE_TAGS: {
      return { ...state, tags: action.payload };
    }

    case UPDATE_SHOW_PAYMENT_MODAL: {
      return { ...state, showPaymentModal: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};

export default jobsReducer;
