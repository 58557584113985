import React, { useState, useEffect, useCallback } from "react";
import { Table, Button } from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addLeaves,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import { Modal, Form, Row, Col } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

const ApproveLeaves = () => {
  const [leaves, setLeaves] = useState([]);
  const [employeeID, setEmployeeID] = useState(null);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeID(response.data.employment_details[0].id);
        console.log(response.data.employment_details[0]);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const fetchLeaves = useCallback(async () => {
    try {
      let endpoint = `${addLeaves}?department=${employeeID}`;
      if (selectedStatus) {
        endpoint += `&${selectedStatus}=true`;
      }
      const response = await axiosInstance.get(endpoint);
      setLeaves(response.data);
    } catch (error) {
      console.error("Error fetching leaves:", error);
      toast.error("Failed to fetch leaves");
    }
  }, [employeeID, selectedStatus]);

  useEffect(() => {
    if (employeeID) {
      fetchLeaves();
    }
  }, [employeeID, fetchLeaves]);

  const handleViewLeave = (leave) => {
    setSelectedLeave(leave);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedLeave(null);
  };

  const handleViewDocument = (documentUrl) => {
    window.open(documentUrl, "_blank");
  };

  const handleApproveLeave = async () => {
    if (!selectedLeave) return;

    try {
      await axiosInstance.patch(`${addLeaves}${selectedLeave.id}/`, {
        is_pending: false,
        is_approved: true,
        approved_by: employeeID,
      });

      toast.success("Leave approved successfully");
      fetchLeaves();
      handleCloseModal();
    } catch (error) {
      console.error("Error approving leave:", error);
      toast.error("Failed to approve leave");
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Employee Name",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
      render: (text) => text || "N/A",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Total Days",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        let statusText = "Pending";
        let backgroundColor = "#ffc107";

        if (record.is_approved && !record.is_pending) {
          statusText = "Approved";
          backgroundColor = "#4caf50";
        }

        return (
          <span
            style={{
              padding: "10px",
              borderRadius: "5px",
              color: "#fff",
              backgroundColor,
            }}
          >
            {statusText}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleViewLeave(record)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="text-3xl font-bold text-gray-700">Approve Leaves</h2>
            <div className="ms-auto d-inline-block">
              <div className="input-block mb-0">
                <select
                  className="form-select mt-1"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value="is_approved">Approved</option>
                  <option value="is_pending">Pending</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body mt-0">
                <div className="leave-list-table">
                  <Table
                    dataSource={leaves}
                    columns={columns}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                    bordered
                    className="table-striped"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Leave Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedLeave && (
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="employeeName">
                      <Form.Label>Employee Name</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={
                          selectedLeave.user_details?.employee_name || "N/A"
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="contactPerson" className="mt-2">
                      <Form.Label>Contact Person</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={
                          selectedLeave.contact_details?.employee_name || "N/A"
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="subject">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        readOnly
                        value={selectedLeave.subject}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="leaveType" className="mt-2">
                      <Form.Label>Leave Type</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={selectedLeave.leave_type}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="startDate" className="mt-2">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={moment(selectedLeave.start_date).format(
                          "DD-MM-YYYY"
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="endDate" className="mt-2">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={moment(selectedLeave.end_date).format(
                          "DD-MM-YYYY"
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="totalDays" className="mt-2">
                      <Form.Label>Total Days</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={selectedLeave.total}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="halfDaySpec" className="mt-2">
                      <Form.Label>Half Day Specification</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={
                          selectedLeave.leave_specification || "None selected"
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="approvedBy" className="mt-2">
                      <Form.Label>Approved By</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={
                          selectedLeave.approved_details?.employee_name || "N/A"
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="reason" className="mt-2">
                  <Form.Label>Reason</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    readOnly
                    value={selectedLeave.reason}
                    style={{ resize: "none" }}
                  />
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            {selectedLeave?.document && (
              <Button
                key="view-doc"
                variant="primary"
                onClick={() => handleViewDocument(selectedLeave.document)}
              >
                View Document
              </Button>
            )}
            {!selectedLeave?.is_approved && (
              <Button variant="primary" onClick={handleApproveLeave}>
                Approve Leave
              </Button>
            )}
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ApproveLeaves;
