import React from "react";
import Header from "../../../../layout/Header";
import ZiiForm from "./ZIIForm";

const AddZII = () => {
  return (
    <>
      <Header />
      <ZiiForm />
    </>
  );
};

export default AddZII;
