export const TechnicalSidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Technical Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/technical-dashboard",
        icon: "la la-dashcube",
      },

      {
        menuValue: "Projects",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-project-diagram",
        subMenus: [
          {
            menuValue: "Projects",
            route: "/technical-projects",
          },
          {
            menuValue: "Project Management",
            route: "/tech-projects-management",
          },

          // {
          //   menuValue: "Task Assigned",
          //   route: "/project-view",
          // },
        ],
      },
    ],
  },
];
