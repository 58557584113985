export const baseURL = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : "https://habot-hr-gacl-dc-30042023.uc.r.appspot.com/";

// Accounts endpoints
export const signUpUrl = "accounts/sign-up/";
export const googleLoginUrl = "accounts/o/google-oauth2/";
export const loginUrl = "accounts/login/";
export const logoutUrl = "accounts/logout/";
export const clientProfileUrl = "accounts/client-profile/";
export const vendorProfileUrl = "accounts/vendor-profile/";
export const verifyOTPUrl = "accounts/verify-otp-token/";
export const refreshTokenUrl = "accounts/token/refresh/";
export const forgotPasswordUrl = "accounts/reset_password/";
export const resetPasswordUrl = "accounts/reset_password_confirm/";
export const UserUrl = "accounts/users/";
export const changePassUrl = "accounts/set_password/";
export const resendOTPUrl = "accounts/resend-otp-token/";
export const vendorGallery = "accounts/vendor-gallery/";
export const vendorVerification = "accounts/vendor-verification-request/";
export const departmentList = "accounts/department/";
export const designationList = "accounts/designation/";
export const createUser = "accounts/create-user/";
export const basicUserDetails = (Userid) =>
  `accounts/basic-user-details/${Userid}/`; //will receive and id
export const employeeDetails = "accounts/employee/";
export const employeeList = "accounts/users/";
export const employmentDetail = "accounts/employment-detail/";

// employee endpoints
export const uploadDocuments = "employee/upload-document/";
export const documentList = "employee/document-list/";
export const namingList = "employee/naming-list/";
export const documentDetail = "employee/document-detail/"; //will receive and id
export const nameShareApi = "employee/name-share/"; //will receive and id
export const ziitopic = "hr/zii-topic/";
export const addZii = "employee/add-zii/";
export const getZii = (userId, monthName, weekNumber) =>
  `employee/add-zii/?user=${userId}&month_name=${monthName}&week_number=${weekNumber}`;
export const patchZii = (ziiID) => `employee/zii/${ziiID}/`;
export const addAppraisal = (
  reportee,
  supervisor,
  year,
  monthName,
  weekNumber
) =>
  `employee/weekly-appraisal/?reportee=${reportee}&supervisor=${supervisor}&week=${weekNumber}&year=${year}&month=${monthName}`;
export const getMonthAppraisal = (reportee, year, monthName) =>
  `employee/weekly-appraisal/?reportee=${reportee}&month=${monthName}&year=${year}`;
export const companyAveragebyWeek = (weekNumber, year, monthName) =>
  `employee/weekly-appraisal-average/${weekNumber}/${monthName}/${year}/`;
export const ziiReport = (yearNumber, monthNumber) =>
  `hr/zii-scores/${yearNumber}/${monthNumber}/`;
export const addLeaves = "employee/leaves/";
export const hrCourses = "hr/internal-courses/";
export const hrCourseById = (userId) => `hr/internal-courses/${userId}/`;
export const departmentAppraisal = (department, year, monthName) =>
  `hr/appraisal-report/?department=${department}&month=${monthName}&year=${year}`;
export const employeeNotifications = "employee/notifications/";
export const patchNotifications = (ID) => `employee/notifications/${ID}/`;
export const enrolledCourses = "employee/course-completion/";
export const patchEnrolledCourses = (ID) => `employee/course-completion/${ID}/`;
export const addPolicy = "hr/policies/";
export const policyFeedback = "hr/policy-feedbacks/";

//technical endpoints
export const projectManagement = "technical/project-management/";
export const projects = "technical/projects/";
