import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal } from "bootstrap";
import Select from "react-select";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  employeeList,
  addPolicy,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";

const AddandEditModal = ({
  selectedPolicy,
  fetchPolicies,
  clearSelectedPolicy,
  isOpen,
  onClose,
}) => {
  const [employeeID, setEmployeeID] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedApprovalPerson, setSelectedApprovalPerson] = useState(null);
  const [selectedComplianceMonitoring, setSelectedComplianceMonitoring] =
    useState([]);
  const [selectedMadeByPersons, setSelectedMadeByPersons] = useState([]);
  const [policyName, setPolicyName] = useState("");
  const [objectives, setObjectives] = useState("");
  const [guidelines, setGuidelines] = useState("");
  const [validUntil, setValidUntil] = useState("");
  const [valueCompatibility, setValueCompatibility] = useState("");
  const [policyGuidelines, setPolicyGuidelines] = useState("");
  const [isAlreadyVerified, setIsAlreadyVerified] = useState(false);
  const [document, setDocument] = useState(null);
  const [existingDocument, setExistingDocument] = useState(null);

  const modalRef = useRef(null);
  const bsModalRef = useRef(null);

  useEffect(() => {
    if (modalRef.current) {
      bsModalRef.current = new Modal(modalRef.current, {
        backdrop: "static",
        keyboard: false,
      });
    }
  }, []);

  useEffect(() => {
    if (bsModalRef.current) {
      if (isOpen) {
        bsModalRef.current.show();
      } else {
        bsModalRef.current.hide();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    axiosInstance.get(employeeDetails).then(
      (response) => {
        setEmployeeID(response.data.employment_details[0].id);
      },
      (error) => {
        console.log(error);
      }
    );

    axiosInstance.get(employeeList).then(
      (response) => {
        const employeeOptions = response.data.map((emp) => ({
          value: emp.id,
          label: emp.employee_name,
        }));
        setEmployees(employeeOptions);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const clearForm = useCallback(() => {
    setPolicyName("");
    setObjectives("");
    setGuidelines("");
    setValidUntil("");
    setValueCompatibility("");
    setPolicyGuidelines("");
    setSelectedApprovalPerson(null);
    setSelectedComplianceMonitoring([]);
    setSelectedMadeByPersons([]);
    setIsAlreadyVerified(false);
    setDocument(null);
    setExistingDocument(null);
    clearSelectedPolicy();
  }, [clearSelectedPolicy]);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("user", employeeID);
    formData.append("name", policyName);
    formData.append("objectives", objectives);
    formData.append("approval_by", selectedApprovalPerson?.value || "");
    formData.append("guidelines", guidelines);
    formData.append("valid_until", validUntil);
    formData.append("value_compatibility", valueCompatibility);
    formData.append("policy_guidelines", policyGuidelines);
    formData.append("is_approved", isAlreadyVerified);

    // Append each compliance_monitoring item individually
    selectedComplianceMonitoring.forEach((item) => {
      formData.append("compliance_monitoring", item.value); // Check your API documentation to see if this is the correct way
    });

    // Append each made_by_persons item individually
    selectedMadeByPersons.forEach((item) => {
      formData.append("made_by_persons", item.value); // Check your API documentation to see if this is the correct way
    });

    // Append document if it exists
    if (document) {
      formData.append("document", document);
    } else if (existingDocument) {
      formData.append("document", existingDocument);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (selectedPolicy && selectedPolicy.id) {
      axiosInstance
        .patch(`${addPolicy}${selectedPolicy.id}/`, formData, config)
        .then((response) => {
          fetchPolicies();
          toast.success("Policy updated successfully!");
          handleClose();
        })
        .catch((error) => {
          console.log("Error updating policy", error);
          toast.error("Failed to update policy");
        });
    } else {
      axiosInstance
        .post(addPolicy, formData, config)
        .then((response) => {
          fetchPolicies();
          toast.success("Policy added successfully!");
          handleClose();
        })
        .catch((error) => {
          console.log("Error adding policy", error);
          toast.error("Failed to add policy");
        });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDocument(file);
      setExistingDocument(null);
    }
  };

  const handleClose = () => {
    clearForm();
    onClose();
  };

  useEffect(() => {
    if (selectedPolicy) {
      setPolicyName(selectedPolicy.policyName || "");
      setObjectives(selectedPolicy.objectives || "");
      setGuidelines(selectedPolicy.guidelines || "");
      setValidUntil(selectedPolicy.validUntil || "");
      setValueCompatibility(selectedPolicy.valueCompatibility || "");
      setPolicyGuidelines(selectedPolicy.policy_guidelines || "");
      setIsAlreadyVerified(selectedPolicy.is_approved || false);
      setExistingDocument(selectedPolicy.document || null);

      const complianceMonitoring = selectedPolicy.complianceMonitoredBy
        ? selectedPolicy.complianceMonitoredBy
            .split(",")
            .map((name) =>
              employees.find((emp) => emp.label.trim() === name.trim())
            )
            .filter((emp) => emp !== undefined)
        : [];

      const madeByPersons = selectedPolicy.madeByPersons
        ? selectedPolicy.madeByPersons
            .split(",")
            .map((name) =>
              employees.find((emp) => emp.label.trim() === name.trim())
            )
            .filter((emp) => emp !== undefined)
        : [];

      setSelectedComplianceMonitoring(complianceMonitoring);
      setSelectedMadeByPersons(madeByPersons);
      setSelectedApprovalPerson(
        employees.find(
          (emp) => emp.label === selectedPolicy.approvalPersonName
        ) || null
      );
    } else {
      clearForm();
    }
  }, [selectedPolicy, employees, clearForm]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
    control: (provided) => ({
      ...provided,
      borderColor: "#ff9b44",
      "&:hover": {
        borderColor: "#ff9b44",
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#ff9b44",
      color: "#fff",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#fff",
      ":hover": {
        backgroundColor: "#ff9b44",
        color: "#fff",
      },
    }),
  };

  return (
    <div
      className="modal fade"
      id="add_policy"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addPolicyModalLabel"
      aria-hidden="true"
      ref={modalRef}
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addPolicyModalLabel">
              {selectedPolicy ? "Edit Policy" : "Add Policy"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Policy Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={policyName}
                      onChange={(e) => setPolicyName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Objectives <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      value={objectives}
                      onChange={(e) => setObjectives(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Value Compatibility
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      value={valueCompatibility}
                      onChange={(e) => setValueCompatibility(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Guidelines <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      value={guidelines}
                      onChange={(e) => setGuidelines(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Approval Person Name
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={employees}
                      placeholder="Select Approval Person"
                      styles={customStyles}
                      value={selectedApprovalPerson}
                      onChange={(option) => setSelectedApprovalPerson(option)}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Compliance to be Monitored By{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={employees}
                      isMulti
                      placeholder="Select Compliance Monitoring"
                      styles={customStyles}
                      value={selectedComplianceMonitoring}
                      onChange={(options) =>
                        setSelectedComplianceMonitoring(options)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Made By Persons <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={employees}
                      isMulti
                      placeholder="Select Persons"
                      styles={customStyles}
                      value={selectedMadeByPersons}
                      onChange={(options) => setSelectedMadeByPersons(options)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Valid Until - Date or Event{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      value={validUntil}
                      onChange={(e) => setValidUntil(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Policy Formulation Guidelines{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      value={policyGuidelines}
                      onChange={(e) => setPolicyGuidelines(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {!existingDocument && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Upload Document (Optional)
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div>
              )}

              {existingDocument && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Existing Document
                      </label>
                      <p>{existingDocument}</p>
                      <button
                        className="btn btn-primary"
                        onClick={() => window.open(existingDocument, "_blank")}
                      >
                        View Document
                      </button>
                      <button
                        className="btn btn-danger ml-2"
                        onClick={() => {
                          setExistingDocument(null);
                          setDocument(null);
                        }}
                      >
                        Remove Document
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-12">
                  <div className="input-block mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="alreadyVerified"
                        checked={isAlreadyVerified}
                        onChange={(e) => setIsAlreadyVerified(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="alreadyVerified"
                      >
                        Already Verified
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  type="button"
                  onClick={handleSubmit}
                >
                  {selectedPolicy ? "Update" : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddandEditModal;
