import React from "react";
import Header from "../../../../layout/Header";

import TechnicalSideBar from "../../../../layout/TechnicalSideBar";
import { Bar } from "react-chartjs-2";

const TechnicalDashboard = () => {
  // const history = useHistory();
  //const location = useLocation();

  const state1 = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Tasks",
        data: [24, 15, 32, 24, 26, 20],
        backgroundColor: "#664dc9",
      },
    ],
  };
  const option1 = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: true,
      labels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 10,
            max: 40,
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 0.6,
          ticks: {
            beginAtZero: true,
            fontSize: 11,
          },
        },
      ],
    },
  };
  return (
    <div>
      <Header />
      <TechnicalSideBar />

      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="employee-alert-box">
                  <div className="alert alert-outline-success alert-dismissible fade show">
                    <div className="employee-alert-request flex items-center">
                      <i className="fas fa-exclamation-circle mr-2" />
                      Portal is on an updation and all the features will be
                      added later.
                    </div>
                    <button
                      type="button"
                      className="btn-close ml-auto"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="fas fa-xmark" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Page Header */}

            <div className="row mb-4">
              <div className="col-12">
                <h1 className="text-2xl font-bold">Welcome Technical Team!</h1>
              </div>
            </div>

            {/* /Charts */}
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Tasks Completed</div>
                </div>
                <div className="card-body">
                  <div>
                    <Bar className="h-300" data={state1} options={option1} />
                  </div>
                </div>
              </div>
            </div>
            {/* <Charts /> */}
            {/* /Charts */}
            {/* <Reports /> */}
            {/* <Statistics /> */}
            <div className="row">
              {/* <InvoiceTable /> */}
              {/* <PaymentTable /> */}
            </div>

            <div className="row">
              {/* <ClientTable /> */}
              {/* <RecentTable /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalDashboard;
