import React, { useState, useEffect } from "react";
import { DatePicker, Input, Upload, Button, Radio, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addLeaves,
  employeeDetails,
  employeeList,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../layout/Header";

const LeaveApplicationForm = () => {
  const { Option } = Select;
  const [subject, setSubject] = useState("");
  const [reason, setReason] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [fileList, setFileList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalDays, setTotalDays] = useState(0);
  const [employeeID, setEmployeeID] = useState(null);
  const [leaveSpecification, setLeaveSpecification] = useState("");
  const [contactPersonID, setContactPersonID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [employeeListData, setEmployeeListData] = useState([]);
  const [isRSRD, setIsRSRD] = useState(false);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeID(response.data.employment_details[0].id);
        console.log(
          response.data.employment_details[0].designation_detail.name
        );
        if (
          response.data.employment_details[0].designation_detail.name ===
            "MD" ||
          response.data.employment_details[0].designation_detail.name === "CEO"
        ) {
          setIsRSRD(true);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  // Fetch employees for the contact person selection
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axiosInstance.get(employeeList, {
          params: { employee_name: searchTerm }, // Pass the search term to the API
        });
        console.log("Fetched Employees: ", response.data); // Debug log
        setEmployeeListData(response.data);
      } catch (error) {
        console.error("Error fetching employee list:", error);
      }
    };

    if (searchTerm) {
      fetchEmployees();
    }
  }, [searchTerm]);

  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList.slice(-1)); // Allow only one file at a time
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (
      leaveSpecification === "First half" ||
      leaveSpecification === "Second half"
    ) {
      setEndDate(date);
    }
    calculateTotalDays(date, date, leaveSpecification);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    calculateTotalDays(startDate, date, leaveSpecification);
  };

  const calculateTotalDays = (start, end, specification) => {
    if (specification === "First half" || specification === "Second half") {
      setTotalDays(0.5);
      setEndDate(start);
    } else if (start && end) {
      let startDate = new Date(start);
      let endDate = new Date(end);
      let totalDays = 0;

      while (startDate <= endDate) {
        if (startDate.getDay() !== 6 && startDate.getDay() !== 0) {
          totalDays++;
        }
        startDate.setDate(startDate.getDate() + 1);
      }
      setTotalDays(totalDays);
    } else {
      setTotalDays(0);
    }
  };

  const validateForm = () => {
    if (!subject.trim()) {
      toast.error("Subject is required");
      return false;
    }
    if (!leaveType.trim()) {
      toast.error("Leave Type is required");
      return false;
    }
    if (!startDate) {
      toast.error("Start date is required");
      return false;
    }
    if (!isRSRD && !contactPersonID) {
      toast.error("Contact person is required");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const formattedStartDate = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : null;
      const formattedEndDate = endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : leaveSpecification === "Full day"
        ? formattedStartDate
        : null;

      const formData = new FormData();
      formData.append("user", employeeID);
      formData.append("subject", subject);
      formData.append("leave_type", leaveType);
      formData.append("start_date", formattedStartDate);
      formData.append("end_date", formattedEndDate);
      formData.append("total", totalDays);
      formData.append("leave_specification", leaveSpecification);
      formData.append("reason", reason);
      if (!isRSRD) {
        formData.append("contact_person", contactPersonID);
      }
      formData.append("is_approved", false);
      formData.append("is_pending", true);

      if (fileList.length > 0) {
        formData.append("document", fileList[0].originFileObj);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      await axiosInstance.post(addLeaves, formData, config);
      toast.success("Leave application submitted successfully");

      setSubject("");
      setLeaveType("");
      setStartDate(null);
      setEndDate(null);
      setTotalDays(0);
      setLeaveSpecification("");
      setFileList([]);
      setReason("");
      setContactPersonID(null);
    } catch (error) {
      toast.error(
        "Error submitting leave application. Please check your input."
      );
      console.error(
        "Error submitting leave application:",
        error.response?.data || error
      );
    }
  };

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header text-center">
              <h2 className="text-3xl font-bold text-gray-700">
                Leave Application Form
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body mt-0">
                  <div className="leave-application-form">
                    <h2 className="text-lg font-bold mb-6 text-center text-gray-700">
                      FILL IN DETAILS
                    </h2>
                    <form>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="form-item">
                          <label className="block text-gray-700 font-semibold mb-2">
                            1. Subject
                          </label>
                          <Input.TextArea
                            placeholder="Fill the Subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                            rows={1}
                          />
                        </div>
                      </div>

                      {!isRSRD && (
                        <div className="form-item col-md-6 mt-2">
                          <label className="block text-gray-700 font-semibold mb-2">
                            2. Contact Person (Employee)
                          </label>
                          <Select
                            showSearch
                            placeholder="Search employee by name"
                            onSearch={(value) => setSearchTerm(value)}
                            onChange={(value) => setContactPersonID(value)}
                            value={contactPersonID}
                            style={{ width: "100%" }}
                            optionLabelProp="label"
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {employeeListData.map((employee) => (
                              <Option
                                key={employee.id}
                                value={employee.id}
                                label={employee.employee_name}
                              >
                                {employee.employee_name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      )}

                      <div className="form-item mt-2">
                        <label className="block text-gray-700 font-semibold mb-2">
                          {isRSRD ? "2" : "3"}. Leave Specification
                        </label>
                        <Radio.Group
                          onChange={(e) => {
                            setLeaveSpecification(e.target.value);
                            calculateTotalDays(
                              startDate,
                              endDate,
                              e.target.value
                            );
                          }}
                          value={leaveSpecification}
                          className="flex space-x-4"
                        >
                          <Radio value="First half">
                            First Half (8:30 AM to 1:30 PM)
                          </Radio>
                          <Radio value="Second half">
                            Second Half (1:30 PM to 6:00 PM)
                          </Radio>
                          <Radio value="Full day">Full Day</Radio>
                        </Radio.Group>
                      </div>

                      <div className="row mt-2">
                        <div className="form-item col-md-3">
                          <label className="block text-gray-700 font-semibold mb-2">
                            {isRSRD ? "3" : "4"}. Leave Type
                          </label>
                          <Input
                            placeholder="Enter the leave type"
                            value={leaveType}
                            onChange={(e) => setLeaveType(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                          />
                        </div>

                        <div className="form-item col-md-3">
                          <label className="block text-gray-700 font-semibold mb-2">
                            {isRSRD ? "4" : "5"}. Start date of Leave
                          </label>
                          <DatePicker
                            placeholder="Select start date"
                            className="w-100"
                            onChange={handleStartDateChange}
                            format="YYYY-MM-DD"
                          />
                        </div>

                        <div className="form-item col-md-3">
                          <label className="block text-gray-700 font-semibold mb-2">
                            {isRSRD ? "5" : "6"}. End date of Leave
                          </label>
                          <DatePicker
                            placeholder="Select end date"
                            className="w-100"
                            onChange={handleEndDateChange}
                            format="YYYY-MM-DD"
                            disabled={
                              leaveSpecification === "First half" ||
                              leaveSpecification === "Second half"
                            }
                          />
                        </div>

                        <div className="form-item col-md-3">
                          <label className="block text-gray-700 font-semibold mb-2">
                            {isRSRD ? "6" : "7"}. Total Number of leave days
                          </label>
                          <Input
                            value={totalDays}
                            disabled
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Automatically calculated"
                          />
                        </div>
                      </div>

                      <div className="form-item mt-2">
                        <label className="block text-gray-700 font-semibold mb-2">
                          {isRSRD ? "7" : "8"}. Reason for Leave
                        </label>
                        <Input.TextArea
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                          placeholder="Fill in the reason for leave"
                          className="w-full p-2 border border-gray-300 rounded"
                          rows={2}
                        />
                      </div>

                      <div className="form-item mt-2">
                        <label className="block text-gray-700 font-semibold mb-2">
                          {isRSRD ? "8" : "9"}. Upload document (optional)
                        </label>
                        <Upload
                          fileList={fileList}
                          onChange={handleUploadChange}
                          beforeUpload={() => false}
                          maxCount={1}
                        >
                          <Button icon={<UploadOutlined />} className="w-full">
                            Upload Document
                          </Button>
                        </Upload>
                      </div>

                      <div className="col-md-3 pt-4">
                        <Button
                          type="primary"
                          onClick={handleSubmit}
                          className="w-50"
                        >
                          Submit
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveApplicationForm;
