import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import { patchZii } from "../../../../../helpers/endpoints/api_endpoints";
import { Button, Modal, Form } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmployeeZIIList = ({ filteredData, hideButtons }) => {
  const [show, setShow] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [documentLists, setDocumentLists] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setDocumentLists(filteredData);
    setTimeout(() => {
      setIsLoading(false);
    }, 1700);
  }, [filteredData]);

  const handleShowDetails = (record, editMode = false) => {
    setSelectedRecord(record);
    setFormData(record);
    setIsEditMode(editMode);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setIsEditMode(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "implementation_cost") {
      // Directly set the string value for implementation_cost
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleSaveChanges = () => {
    // Ensure the cost is not empty, if it is, prompt to enter "0"
    if (formData.implementation_cost.trim() === "") {
      toast.error(
        "Implementation cost is required. Please enter 0 if there is no cost."
      );
      return;
    }

    const updatedData = { ...formData, updated_at: new Date().toISOString() };
    axiosInstance
      .patch(patchZii(selectedRecord.id), updatedData)
      .then((response) => {
        const updatedList = documentLists.map((item) =>
          item.id === selectedRecord.id ? response.data : item
        );
        setDocumentLists(updatedList);
        setShow(false);
        setIsEditMode(false);
        toast.success("Record updated successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to update the record.");
      });
  };

  const handleDelete = (recordId) => {
    axiosInstance
      .delete(patchZii(recordId))
      .then(() => {
        const updatedList = documentLists.filter(
          (item) => item.id !== recordId
        );
        setDocumentLists(updatedList);
        toast.success("Record deleted successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to delete the record.");
      });
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at),
      render: (created_at) => (
        <div>{new Date(created_at).toLocaleDateString()}</div>
      ),
    },
    {
      title: "Situation Before",
      dataIndex: "situation_before",
      key: "situation_before",
      render: (text) => <div>{text ? text.substring(0, 20) + "..." : ""}</div>,
    },
    {
      title: "Benefits",
      dataIndex: "benefits",
      key: "benefits",
      render: (text) => <div>{text ? text.substring(0, 20) + "..." : ""}</div>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <>
          <Button
            onClick={() => handleShowDetails(record)}
            className="btn btn-primary me-2"
          >
            Show Details
          </Button>
          {hideButtons ? (
            <></>
          ) : (
            <>
              <Button
                onClick={() => handleShowDetails(record, true)}
                className="btn btn-secondary me-2"
              >
                Edit
              </Button>
              <Button
                onClick={() => handleDelete(record.id)}
                className="btn btn-danger"
              >
                Delete
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  const fieldsForTotal = [
    "problem_stage",
    "area",
    "efforts",
    "method",
    "role",
    "idea_source",
    "bonus",
  ];

  const calculateTotal = (record) => {
    return fieldsForTotal.reduce((total, field) => {
      return total + (record[field] || 0);
    }, 0);
  };

  const calculateOverallTotal = (lists) => {
    return lists.reduce((overallTotal, record) => {
      return overallTotal + calculateTotal(record);
    }, 0);
  };

  const calculateAverage = (overallTotal, length) => {
    return length > 0 ? (overallTotal / length).toFixed(2) : 0;
  };

  const overallTotal = calculateOverallTotal(documentLists);
  const average = calculateAverage(overallTotal, documentLists.length);

  // const formatDate = (dateString) => {
  //   const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  //   return new Date(dateString).toLocaleDateString("en-GB", options);
  // };

  const problemStageOptions = [
    { label: "Seed-40", value: 40 },
    { label: "Sprout-30", value: 30 },
    { label: "Bud-20", value: 20 },
    { label: "Flower-10", value: 10 },
  ];

  const areaOptions = [
    { label: "Job-10", value: 10 },
    { label: "Department-8", value: 8 },
    { label: "Outside-6", value: 6 },
    { label: "Personal-2", value: 2 },
  ];

  const effortsOptions = [
    { label: "Very Hard-10", value: 10 },
    { label: "Hard-6", value: 6 },
    { label: "Average-4", value: 4 },
    { label: "Little-2", value: 2 },
  ];

  const methodOptions = [
    { label: "Existing Team-10", value: 10 },
    { label: "New Team-8", value: 8 },
    { label: "Forced Team-5", value: 5 },
    { label: "Self-3", value: 3 },
  ];

  const roleOptions = [
    { label: "Implementer-10", value: 10 },
    { label: "Part of Team-8", value: 8 },
    { label: "Decision-7", value: 7 },
    { label: "Idea-5", value: 5 },
  ];

  const ideaSourceOptions = [
    { label: "Colleague-10", value: 10 },
    { label: "Senior/Junior-7", value: 7 },
    { label: "Acceptor-4", value: 4 },
    { label: "Generator-2", value: 2 },
  ];

  const bonusOptions = [
    { label: "Monthly Campaign / Expertise Gain-10", value: 10 },
    { label: "System Implementation-9", value: 9 },
    { label: "Cost Reduction-8", value: 8 },
    { label: "Other-6", value: 6 },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "38px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "38px",
    }),
  };

  const handleSelectChange = (name, selectedOption) => {
    setFormData({
      ...formData,
      [name]: selectedOption ? selectedOption.value : null,
    });
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={documentLists}
            rowKey={(record) => record.id}
            loading={isLoading}
          />
          <div className="row col-md-12 text-right">
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex align-items-center">
                <h5 className="mb-0 fw-bold">Week's Total:</h5>
                <h6 className="mb-0 ms-2 ">{overallTotal}</h6>
              </div>
            </div>
            <div className="d-flex justify-content-between w-100 mt-2">
              <div className="d-flex align-items-center">
                <h5 className="mb-0 fw-bold">Average:</h5>
                <h6 className="mb-0 ms-2 ">{average}</h6>
              </div>
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-md-12">
              <div className="employee-alert-box">
                <div className="alert alert-outline-success alert-dismissible fade show flex items-center p-2">
                  <div className="employee-alert-request flex items-center">
                    <i className="fas fa-exclamation-circle mr-2" />
                    You should at least have 5 ZII's every month.
                  </div>
                  <button
                    type="button"
                    className="btn-close ml-auto"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{isEditMode ? "Edit Details" : "Details"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedRecord && (
              <Form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Situation Before</label>
                      <textarea
                        className="form-control"
                        name="situation_before"
                        value={formData.situation_before || ""}
                        readOnly={!isEditMode}
                        onChange={handleInputChange}
                        style={{
                          height: "calc(100% - 2.5rem)",
                          minHeight: "70px",
                          overflow: "auto",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Changes</label>
                      <textarea
                        className="form-control"
                        name="changes"
                        value={formData.changes || ""}
                        readOnly={!isEditMode}
                        onChange={handleInputChange}
                        style={{
                          height: "calc(100% - 2.5rem)",
                          minHeight: "70px",
                          overflow: "auto",
                        }}
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Result</label>
                      <textarea
                        className="form-control"
                        name="result"
                        value={formData.result || ""}
                        readOnly={!isEditMode}
                        onChange={handleInputChange}
                        style={{
                          height: "calc(100% - 2.5rem)",
                          minHeight: "70px",
                          overflow: "auto",
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">My Role</label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="my_role"
                        value={formData.my_role || ""}
                        readOnly={!isEditMode}
                        onChange={handleInputChange}
                        style={{ minHeight: "70px" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Whose Idea</label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="whose_idea"
                        value={formData.whose_idea || ""}
                        readOnly={!isEditMode}
                        onChange={handleInputChange}
                        style={{ minHeight: "70px" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Implementation Date
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="implementation_date"
                        value={formData.implementation_date}
                        readOnly={!isEditMode}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Implementation Cost
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="implementation_cost"
                        value={
                          formData.implementation_cost !== undefined
                            ? formData.implementation_cost
                            : ""
                        }
                        readOnly={!isEditMode}
                        onChange={handleInputChange} // No need for parsing, as we now accept strings directly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Benefits</label>
                    <textarea
                      className="form-control"
                      name="benefits"
                      value={formData.benefits || ""}
                      readOnly={!isEditMode}
                      onChange={handleInputChange}
                      style={{
                        height: "calc(100% - 2.5rem)",
                        minHeight: "70px",
                        overflow: "auto",
                      }}
                    ></textarea>
                  </div>
                </div>
                {isEditMode && (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Problem Stage</label>
                        <Select
                          options={problemStageOptions}
                          placeholder="Select..."
                          styles={customStyles}
                          value={problemStageOptions.find(
                            (option) => option.value === formData.problem_stage
                          )}
                          onChange={(selectedOption) =>
                            handleSelectChange("problem_stage", selectedOption)
                          }
                          isDisabled={!isEditMode}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Area</label>
                        <Select
                          options={areaOptions}
                          placeholder="Select..."
                          styles={customStyles}
                          value={areaOptions.find(
                            (option) => option.value === formData.area
                          )}
                          onChange={(selectedOption) =>
                            handleSelectChange("area", selectedOption)
                          }
                          isDisabled={!isEditMode}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Efforts</label>
                        <Select
                          options={effortsOptions}
                          placeholder="Select..."
                          styles={customStyles}
                          value={effortsOptions.find(
                            (option) => option.value === formData.efforts
                          )}
                          onChange={(selectedOption) =>
                            handleSelectChange("efforts", selectedOption)
                          }
                          isDisabled={!isEditMode}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Method</label>
                        <Select
                          options={methodOptions}
                          placeholder="Select..."
                          styles={customStyles}
                          value={methodOptions.find(
                            (option) => option.value === formData.method
                          )}
                          onChange={(selectedOption) =>
                            handleSelectChange("method", selectedOption)
                          }
                          isDisabled={!isEditMode}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Role</label>
                        <Select
                          options={roleOptions}
                          placeholder="Select..."
                          styles={customStyles}
                          value={roleOptions.find(
                            (option) => option.value === formData.role
                          )}
                          onChange={(selectedOption) =>
                            handleSelectChange("role", selectedOption)
                          }
                          isDisabled={!isEditMode}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Idea Source</label>
                        <Select
                          options={ideaSourceOptions}
                          placeholder="Select..."
                          styles={customStyles}
                          value={ideaSourceOptions.find(
                            (option) => option.value === formData.idea_source
                          )}
                          onChange={(selectedOption) =>
                            handleSelectChange("idea_source", selectedOption)
                          }
                          isDisabled={!isEditMode}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Bonus</label>
                        <Select
                          options={bonusOptions}
                          placeholder="Select..."
                          styles={customStyles}
                          value={bonusOptions.find(
                            (option) => option.value === formData.bonus
                          )}
                          onChange={(selectedOption) =>
                            handleSelectChange("bonus", selectedOption)
                          }
                          isDisabled={!isEditMode}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!isEditMode && (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="input-block mb-3">
                        <label
                          className="col-form-label"
                          style={{ fontWeight: "bold" }}
                        >
                          Total
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={calculateTotal(formData)}
                          readOnly
                          style={{
                            fontWeight: "bold",
                            borderWidth: "3px",
                            borderColor: "black",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            {isEditMode && (
              <Button variant="primary" onClick={handleSaveChanges}>
                Save Changes
              </Button>
            )}
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default EmployeeZIIList;
