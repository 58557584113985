export const SidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "HR Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/hr-dashboard",
        icon: "la la-dashcube",
      },

      {
        menuValue: "Employees",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-user",
        subMenus: [
          {
            menuValue: "Add Employee",
            route: "/employees",
          },
          {
            menuValue: "All Employee",
            route: "/employeeDetails",
          },
        ],
      },
      {
        menuValue: "ZII",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-list",
        subMenus: [
          {
            menuValue: "ZII Topics",
            route: "/zii-topic",
          },
          {
            menuValue: "ZII Reports",
            route: "/zii-reports",
          },
        ],
      },
      {
        menuValue: "Training",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-graduation-cap",
        subMenus: [
          {
            menuValue: "Approval List",
            route: "/training-reports",
          },
          {
            menuValue: "Certificate Verification",
            route: "/training-verification",
          },
        ],
      },
      {
        menuValue: "Policies",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-file-pdf-o",
        subMenus: [
          {
            menuValue: "Add New Policy",
            route: "/add-policies",
          },
        ],
      },
      {
        menuValue: "Appraisal Reports",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-star-o",
        subMenus: [
          {
            menuValue: "Monthly Report",
            route: "/appraisal-report",
          },
        ],
      },
      {
        menuValue: "Leaves",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-calendar",
        subMenus: [
          {
            menuValue: "Approve Leaves",
            route: "/hr-leaves",
          },
        ],
      },
    ],
  },
];
