import React, { useState, useEffect, useCallback } from "react";
import { Table, Button } from "antd";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addLeaves,
  employeeList,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";

const HRApproveLeaves = () => {
  const [leaves, setLeaves] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [employeeID, setEmployeeID] = useState(null);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeID(response.data.employment_details[0].id);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const fetchLeaves = useCallback(async () => {
    try {
      let query = `${addLeaves}?`;
      if (selectedEmployeeId) query += `user=${selectedEmployeeId}&`;
      if (selectedStatus) query += `${selectedStatus}=true&`;

      const response = await axiosInstance.get(query);
      setLeaves(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching leaves:", error);
      toast.error("Failed to fetch leaves");
    }
  }, [selectedEmployeeId, selectedStatus]);

  useEffect(() => {
    fetchLeaves();
  }, [fetchLeaves]);

  const handleEmployeeSearch = async (e) => {
    const searchValue = e.target.value;
    setEmployeeSearch(searchValue);

    if (searchValue.length > 1) {
      try {
        const response = await axiosInstance.get(
          `${employeeList}?search=${searchValue}`
        );
        const filtered = response.data.filter((employee) =>
          employee.employee_name
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        );
        setFilteredEmployees(filtered);
      } catch (error) {
        console.error("Error fetching employee list:", error);
      }
    } else if (searchValue === "") {
      // If the search input is cleared, reset the selected employee ID
      setSelectedEmployeeId(null);
      setFilteredEmployees([]);
      fetchLeaves(); // Re-fetch the leaves without any employee filter
    } else {
      setFilteredEmployees([]);
    }
  };

  const handleSelectEmployee = (employee) => {
    setSelectedEmployeeId(employee.id);
    setEmployeeSearch(employee.employee_name);
    setFilteredEmployees([]);
    fetchLeaves(); // Re-fetch the leaves for the selected employee
  };

  const handleViewLeave = async (leave) => {
    setSelectedLeave(leave);

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedLeave(null);
  };

  const handleViewDocument = (documentUrl) => {
    window.open(documentUrl, "_blank");
  };

  const handleApproveLeave = async () => {
    if (!selectedLeave) return;

    try {
      await axiosInstance.patch(`${addLeaves}${selectedLeave.id}/`, {
        is_pending: false,
        is_approved: true,
        approved_by: employeeID,
      });

      toast.success("Leave approved successfully");
      fetchLeaves(); // Refresh the leaves list
      handleCloseModal(); // Close the modal
    } catch (error) {
      console.error("Error approving leave:", error);
      toast.error("Failed to approve leave");
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Employee Name",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
      render: (_, record) => record.user_details?.employee_name || "N/A",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Total Days",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        let statusText = "Pending";
        let backgroundColor = "#ffc107";

        if (record.is_approved && !record.is_pending) {
          statusText = "Approved";
          backgroundColor = "#4caf50";
        }

        return (
          <span
            style={{
              padding: "10px",
              borderRadius: "5px",
              color: "#fff",
              backgroundColor,
            }}
          >
            {statusText}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleViewLeave(record)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header row">
              <h2 className="col-md-6 text-3xl font-bold text-gray-700">
                Approve Leaves
              </h2>
              <div className="col-md-3 mb-0 d-inline-block">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Employee Name"
                  value={employeeSearch}
                  onChange={handleEmployeeSearch}
                />
                {filteredEmployees.length > 0 && (
                  <ul
                    className="list-group position-absolute mt-1 w-80"
                    style={{ zIndex: 1000 }}
                  >
                    {filteredEmployees.map((employee) => (
                      <li
                        key={employee.id}
                        className="list-group-item list-group-item-action"
                        onClick={() => handleSelectEmployee(employee)}
                      >
                        {employee.employee_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="col-md-3 mb-0 d-inline-block">
                <select
                  className="form-select mt-1"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value="is_approved">Approved</option>
                  <option value="is_pending">Pending</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body mt-0">
                  <div className="leave-list-table">
                    <Table
                      dataSource={leaves}
                      columns={columns}
                      rowKey="id"
                      pagination={{ pageSize: 10 }}
                      bordered
                      className="table-striped"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Leave Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedLeave && (
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="employeeName">
                        <Form.Label>Employee Name</Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          value={
                            selectedLeave.user_details?.employee_name || "N/A"
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="contactPerson" className="mt-2">
                        <Form.Label>Contact Person</Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          value={
                            selectedLeave.contact_details?.employee_name ||
                            "N/A"
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="subject">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          readOnly
                          value={selectedLeave.subject}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="leaveType" className="mt-2">
                        <Form.Label>Leave Type</Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          value={selectedLeave.leave_type}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="startDate" className="mt-2">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          value={moment(selectedLeave.start_date).format(
                            "DD-MM-YYYY"
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="endDate" className="mt-2">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          value={moment(selectedLeave.end_date).format(
                            "DD-MM-YYYY"
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="totalDays" className="mt-2">
                        <Form.Label>Total Days</Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          value={selectedLeave.total}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="halfDaySpec" className="mt-2">
                        <Form.Label>Half Day Specification</Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          value={
                            selectedLeave.leave_specification
                              ? selectedLeave.leave_specification
                              : "None selected"
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="approvedBy" className="mt-2">
                        <Form.Label>Approved By</Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          value={
                            selectedLeave.approved_details?.employee_name ||
                            "N/A"
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group controlId="reason" className="mt-2">
                    <Form.Label>Reason</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      readOnly
                      value={selectedLeave.reason}
                      style={{ resize: "none" }}
                    />
                  </Form.Group>
                </Form>
              )}
            </Modal.Body>
            <Modal.Footer>
              {selectedLeave?.document && (
                <Button
                  key="view-doc"
                  variant="primary"
                  onClick={() => handleViewDocument(selectedLeave.document)}
                >
                  View Document
                </Button>
              )}
              {!selectedLeave?.is_approved && (
                <Button variant="primary" onClick={handleApproveLeave}>
                  Approve Leave
                </Button>
              )}
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default HRApproveLeaves;
