import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  employeeDetails,
  addZii,
  basicUserDetails,
  ziitopic,
} from "../../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";

const ZiiForm = () => {
  const [situationBefore, setSituationBefore] = useState("");
  const [changes, setChanges] = useState("");
  const [myRole, setMyRole] = useState("");
  const [whoseIdea, setWhoseIdea] = useState("");
  const [implementationDate, setImplementationDate] = useState("");
  const [implementationCost, setImplementationCost] = useState("");
  const [benefits, setBenefits] = useState("");
  const [problemStage, setProblemStage] = useState(null);
  const [area, setArea] = useState(null);
  const [efforts, setEfforts] = useState(null);
  const [method, setMethod] = useState(null);
  const [role, setRole] = useState(null);
  const [ideaSource, setIdeaSource] = useState(null);
  const [bonus, setBonus] = useState(null);
  const [result, setResult] = useState("");
  const [user, setUser] = useState("");
  const [employeeDetail, setEmployeeDetails] = useState(null);
  const [errors, setErrors] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [ziiTopic, setZiiTopic] = useState(null);

  useEffect(() => {
    axiosInstance
      .get(ziitopic)
      .then((response) => setZiiTopic(response.data[0]))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axiosInstance.get(employeeDetails).then(
      (response) => {
        setEmployeeDetails(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    if (employeeDetail) {
      const Userid = employeeDetail.user.id;
      axiosInstance.get(basicUserDetails(Userid)).then((response) => {
        setUser(response.data.employee_name);
        setUserDetails(response.data);
      });
    }
  }, [employeeDetail]);

  useEffect(() => {
    if (isSaved) {
      // Reset form fields after saving
      setSituationBefore("");
      setChanges("");
      setMyRole("");
      setWhoseIdea("");
      setImplementationDate("");
      setImplementationCost("");
      setBenefits("");
      setProblemStage(null);
      setArea(null);
      setEfforts(null);
      setMethod(null);
      setRole(null);
      setIdeaSource(null);
      setBonus(null);
      setResult("");
      setErrors({});
      setIsSaved(false);
    }
  }, [isSaved]);

  const problemStageOptions = [
    { label: "Seed-40", value: 40 },
    { label: "Sprout-30", value: 30 },
    { label: "Bud-20", value: 20 },
    { label: "Flower-10", value: 10 },
  ];

  const areaOptions = [
    { label: "Job-10", value: 10 },
    { label: "Department-8", value: 8 },
    { label: "Outside-6", value: 6 },
    { label: "Personal-2", value: 2 },
  ];

  const effortsOptions = [
    { label: "Very Hard-10", value: 10 },
    { label: "Hard-6", value: 6 },
    { label: "Average-4", value: 4 },
    { label: "Little-2", value: 2 },
  ];

  const methodOptions = [
    { label: "Existing Team-10", value: 10 },
    { label: "New Team-8", value: 8 },
    { label: "Forced Team-5", value: 5 },
    { label: "Self-3", value: 3 },
  ];

  const roleOptions = [
    { label: "Implementer-10", value: 10 },
    { label: "Part of Team-8", value: 8 },
    { label: "Decision-7", value: 7 },
    { label: "Idea-5", value: 5 },
  ];

  const ideaSourceOptions = [
    { label: "Colleague-10", value: 10 },
    { label: "Senior/Junior-7", value: 7 },
    { label: "Acceptor-4", value: 4 },
    { label: "Generator-2", value: 2 },
  ];

  const bonusOptions = [
    { label: "Monthly Campaign / Expertise Gain-10", value: 10 },
    { label: "System Implementation-9", value: 9 },
    { label: "Cost Reduction-8", value: 8 },
    { label: "Other-6", value: 6 },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleSave = () => {
    const newErrors = {};

    const formData = {
      situation_before: situationBefore,
      changes: changes,
      my_role: myRole,
      whose_idea: whoseIdea,
      implementation_date: implementationDate,
      implementation_cost: implementationCost, // Now directly accepting the string value
      benefits: benefits,
      problem_stage: problemStage ? problemStage.value : null,
      area: area ? area.value : null,
      efforts: efforts ? efforts.value : null,
      method: method ? method.value : null,
      role: role ? role.value : null,
      idea_source: ideaSource ? ideaSource.value : null,
      bonus: bonus ? bonus.value : null,
      result: result ? result : null,
      user: userDetails ? userDetails.id : null,
      topic: ziiTopic?.id || null,
    };

    // Validate required fields
    if (!situationBefore.trim()) {
      newErrors.situationBefore = "Situation before is required";
    }
    if (!changes.trim()) {
      newErrors.changes = "Changes are required";
    }
    if (!myRole.trim()) {
      newErrors.myRole = "My role is required";
    }
    if (!whoseIdea.trim()) {
      newErrors.whoseIdea = "Whose idea is required";
    }
    if (!implementationDate.trim()) {
      newErrors.implementationDate = "Implementation date is required";
    }
    if (!benefits.trim()) {
      newErrors.benefits = "Benefits are required";
    }
    if (!result.trim()) {
      newErrors.result = "Result is required";
    }
    if (!problemStage) {
      newErrors.problemStage = "Problem stage is required";
    }
    if (!area) {
      newErrors.area = "Area is required";
    }
    if (!efforts) {
      newErrors.efforts = "Efforts are required";
    }
    if (!method) {
      newErrors.method = "Method is required";
    }
    if (!role) {
      newErrors.role = "Role is required";
    }
    if (!ideaSource) {
      newErrors.ideaSource = "Idea source is required";
    }
    if (!bonus) {
      newErrors.bonus = "Bonus is required";
    }
    if (!user) {
      newErrors.user = "User is required";
    }

    // Validation for implementationCost
    if (!implementationCost.trim()) {
      newErrors.implementationCost =
        "Implementation cost is required. Please enter 0 if there is no cost.";
    }

    setErrors(newErrors);

    // If there are no errors, proceed with saving the form data
    if (Object.keys(newErrors).length === 0) {
      axiosInstance
        .post(addZii, formData)
        .then((response) => {
          toast.success("Form saved successfully");
          setIsSaved(true);
        })
        .catch((error) => {
          toast.error("Error saving form");
        });
    }
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="content-page-header">
              <h2>ZII Form</h2>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            {/* Lightbox */}
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body mt-0">
                  <div className="wizard">
                    <div className="" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        role="tabpanel"
                        id="step1"
                        aria-labelledby="step1-tab"
                      >
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  ZII Topic of the Month
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  value={ziiTopic?.topic || ""}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Situation before
                                </label>
                                <textarea
                                  className="form-control"
                                  value={situationBefore}
                                  onChange={(e) =>
                                    setSituationBefore(e.target.value)
                                  }
                                  style={{
                                    minHeight: "70px", // Adjust this value as needed
                                  }}
                                ></textarea>
                                {errors.situationBefore && (
                                  <span className="text-danger">
                                    {errors.situationBefore}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Changes
                                </label>
                                <textarea
                                  className="form-control"
                                  value={changes}
                                  onChange={(e) => setChanges(e.target.value)}
                                  style={{
                                    minHeight: "70px", // Adjust this value as needed
                                  }}
                                ></textarea>
                                {errors.changes && (
                                  <span className="text-danger">
                                    {errors.changes}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">Result</label>
                                <textarea
                                  className="form-control"
                                  value={result}
                                  onChange={(e) => setResult(e.target.value)}
                                  style={{
                                    minHeight: "70px", // Adjust this value as needed
                                  }}
                                ></textarea>
                                {errors.result && (
                                  <span className="text-danger">
                                    {errors.result}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  My role
                                </label>
                                <textarea
                                  className="form-control"
                                  value={myRole}
                                  onChange={(e) => setMyRole(e.target.value)}
                                  style={{
                                    minHeight: "70px", // Adjust this value as needed
                                  }}
                                ></textarea>
                                {errors.myRole && (
                                  <span className="text-danger">
                                    {errors.myRole}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Whose idea
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={whoseIdea}
                                  onChange={(e) => setWhoseIdea(e.target.value)}
                                />
                                {errors.whoseIdea && (
                                  <span className="text-danger">
                                    {errors.whoseIdea}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Implementation date
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={implementationDate}
                                  onChange={(e) =>
                                    setImplementationDate(e.target.value)
                                  }
                                  // placeholder="DD/MM/YYYY"
                                />
                                {errors.implementationDate && (
                                  <span className="text-danger">
                                    {errors.implementationDate}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Implementation cost
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={implementationCost}
                                  onChange={(e) =>
                                    setImplementationCost(e.target.value)
                                  } // Directly set the string value
                                  placeholder="Enter cost"
                                />
                                {errors.implementationCost && (
                                  <span className="text-danger">
                                    {errors.implementationCost}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Benefits
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={benefits}
                                  onChange={(e) => setBenefits(e.target.value)}
                                />
                                {errors.benefits && (
                                  <span className="text-danger">
                                    {errors.benefits}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Problem stage
                                </label>
                                <Select
                                  options={problemStageOptions}
                                  placeholder="Select..."
                                  styles={customStyles}
                                  value={problemStage}
                                  onChange={(selectedOption) =>
                                    setProblemStage(selectedOption)
                                  }
                                />
                                {errors.problemStage && (
                                  <span className="text-danger">
                                    {errors.problemStage}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">Area</label>
                                <Select
                                  options={areaOptions}
                                  placeholder="Select..."
                                  styles={customStyles}
                                  value={area}
                                  onChange={(selectedOption) =>
                                    setArea(selectedOption)
                                  }
                                />
                                {errors.area && (
                                  <span className="text-danger">
                                    {errors.area}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Efforts
                                </label>
                                <Select
                                  options={effortsOptions}
                                  placeholder="Select..."
                                  styles={customStyles}
                                  value={efforts}
                                  onChange={(selectedOption) =>
                                    setEfforts(selectedOption)
                                  }
                                />
                                {errors.efforts && (
                                  <span className="text-danger">
                                    {errors.efforts}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">Method</label>
                                <Select
                                  options={methodOptions}
                                  placeholder="Select..."
                                  styles={customStyles}
                                  value={method}
                                  onChange={(selectedOption) =>
                                    setMethod(selectedOption)
                                  }
                                />
                                {errors.method && (
                                  <span className="text-danger">
                                    {errors.method}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row d-flex justify-content-center">
                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">Role</label>
                                <Select
                                  options={roleOptions}
                                  placeholder="Select..."
                                  styles={customStyles}
                                  value={role}
                                  onChange={(selectedOption) =>
                                    setRole(selectedOption)
                                  }
                                />
                                {errors.role && (
                                  <span className="text-danger">
                                    {errors.role}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Source of Idea
                                </label>
                                <Select
                                  options={ideaSourceOptions}
                                  placeholder="Select..."
                                  styles={customStyles}
                                  value={ideaSource}
                                  onChange={(selectedOption) =>
                                    setIdeaSource(selectedOption)
                                  }
                                />
                                {errors.ideaSource && (
                                  <span className="text-danger">
                                    {errors.ideaSource}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Bonus Points
                                </label>
                                <Select
                                  options={bonusOptions}
                                  placeholder="Select..."
                                  styles={customStyles}
                                  value={bonus}
                                  onChange={(selectedOption) =>
                                    setBonus(selectedOption)
                                  }
                                />
                                {errors.bonus && (
                                  <span className="text-danger">
                                    {errors.bonus}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3 pt-4">
                              <Link
                                className="btn btn-primary w-100"
                                onClick={() => handleSave()}
                              >
                                Save
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ZiiForm;
