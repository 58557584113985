import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { avatar19 } from "../../../../../Routes/ImagePath";
import { ArrowRightCircle } from "react-feather";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  employeeDetails,
  employeeNotifications,
  patchNotifications,
} from "../../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../../../../../store/actions/accountsActions";
import { debounce } from "lodash"; // Make sure to import lodash

const EmployeeDashboard = () => {
  const [employeeDetail, setEmployeeDetails] = useState();
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [isPageReloaded, setIsPageReloaded] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const debouncedHandleLogout = useCallback(
    debounce(() => {
      if (loggingOut) return; // Prevent multiple logout attempts
      setLoggingOut(true);
      dispatch({ type: LOGOUT });
      localStorage.removeItem("accessToken");
      navigate("/", { replace: true });
      setLoggingOut(false);
    }, 300),
    [dispatch, navigate, loggingOut]
  );

  useEffect(() => {
    const isFirstTime = sessionStorage.getItem("isFirstTimeOnDashboard");

    if (!isFirstTime) {
      sessionStorage.setItem("isFirstTimeOnDashboard", "true");
      sessionStorage.setItem("showToast", "true");
      setIsPageReloaded(true);
    } else {
      setIsPageReloaded(true);
    }
  }, []);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeDetails(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    axiosInstance.get(`${employeeNotifications}?is_read=false`).then(
      (response) => {
        const top20UnreadNotifications = response.data.slice(0, 20);
        setUnreadNotifications(top20UnreadNotifications);
      },
      (error) => {
        console.log(error);
      }
    );

    axiosInstance.get(`${employeeNotifications}?is_read=true`).then(
      (response) => {
        const top20ReadNotifications = response.data.slice(0, 20);
        setReadNotifications(top20ReadNotifications);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    if (isPageReloaded && sessionStorage.getItem("showToast") === "true") {
      toast.success("Log in Successful");
      sessionStorage.removeItem("showToast");
    }
  }, [isPageReloaded]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      debouncedHandleLogout();
    }
  }, [debouncedHandleLogout]);

  const markAsRead = (id) => {
    axiosInstance
      .patch(patchNotifications(id), { is_read: true })
      .then((response) => {
        const updatedUnreadNotifications = unreadNotifications.filter(
          (notification) => notification.id !== id
        );
        const movedNotification = unreadNotifications.find(
          (notification) => notification.id === id
        );
        setUnreadNotifications(updatedUnreadNotifications);
        setReadNotifications([movedNotification, ...readNotifications]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          <div className="row">
            <div className="col-md-12">
              <div className="employee-alert-box">
                <div className="alert alert-outline-success alert-dismissible fade show">
                  <div className="employee-alert-request">
                    <i className="far fa-circle-question" />
                    Portal is on an updation and all the features will be added
                    later!!!
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  >
                    <i className="fas fa-xmark" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-8 col-lg-12 col-md-12">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="card employee-welcome-card flex-fill">
                    <div className="card-body">
                      <div className="welcome-info">
                        <div className="welcome-content">
                          <h4>
                            Welcome,{employeeDetail?.employee_name || "NA"}
                          </h4>
                          <p>
                            You have <span>0 meetings</span> today,
                          </p>
                        </div>
                        <div className="welcome-img">
                          <img
                            src={avatar19}
                            className="img-fluid"
                            alt="User"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-lg-12 col-md-12 d-flex">
              <div className="card flex-fill">
                <div className="card-body">
                  <div className="statistic-header">
                    <h4>Important</h4>
                    <div className="important-notification">
                      <Link to="/activities">
                        <span className="me-1">View All</span>
                        <ArrowRightCircle size={15} />
                      </Link>
                    </div>
                  </div>
                  <div className="notification-tab">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#unread_notification_tab"
                        >
                          <i className="la la-bell" /> Unread
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#read_notification_tab"
                        >
                          <i className="la la-check-circle" /> Read
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane active"
                        id="unread_notification_tab"
                      >
                        <div
                          className="employee-noti-content"
                          style={{ maxHeight: "400px", overflowY: "auto" }}
                        >
                          <ul className="employee-notification-list">
                            {unreadNotifications.length > 0 ? (
                              unreadNotifications.map((notification) => (
                                <NotificationItem
                                  key={notification.id}
                                  notification={notification}
                                  markAsRead={markAsRead}
                                />
                              ))
                            ) : (
                              <li className="employee-notification-grid">
                                <div className="employee-notification-content">
                                  <h6>No unread notifications!</h6>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="tab-pane" id="read_notification_tab">
                        <div
                          className="employee-noti-content"
                          style={{ maxHeight: "400px", overflowY: "auto" }}
                        >
                          <ul className="employee-notification-list">
                            {readNotifications.length > 0 ? (
                              readNotifications.map((notification) => (
                                <NotificationItem
                                  key={notification.id}
                                  notification={notification}
                                  markAsRead={() => {}}
                                  isRead={true}
                                />
                              ))
                            ) : (
                              <li className="employee-notification-grid">
                                <div className="employee-notification-content">
                                  <h6>No read notifications!</h6>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NotificationItem = ({ notification, markAsRead, isRead = false }) => {
  const getLinkTo = (type) => {
    switch (type) {
      case "course_added":
        return "/training-reports";
      case "course_rs":
        return "/training-approval";
      case "course_approval":
        return "/training-reports";
      case "course_publish":
        return "/employee-courses";
      case "policy_rs":
        return "/policy-approval";
      case "feedback_added":
        return "/add-policies";
      case "policy_added":
        return "/add-policies";
      case "policy_approval":
        return "/add-policies";
      case "policy_emp":
        return "/employee-policy";
      case "appraisal":
        return "/appraisal-table";
      case "leaves_hr":
        return "/hr-leaves";
      case "leaves_sup":
        return "/approve-leaves";
      case "leaves_emp":
        return "/your-leaves";
      case "leaves_noti":
        return "/employee-leaves";
      default:
        return "/activities";
    }
  };

  return (
    <li
      className="employee-notification-grid"
      style={{
        backgroundColor: isRead ? "#e0f7fa" : "#fff3e0",
        padding: "10px",
        margin: "5px 0",
        borderRadius: "5px",
      }}
      onClick={() => !isRead && markAsRead(notification.id)}
    >
      <div className="employee-notification-icon">
        <Link to={getLinkTo(notification.type)}>
          <span className="badge-soft-danger rounded-circle">HR</span>
        </Link>
      </div>
      <div className="employee-notification-content">
        <Link to={getLinkTo(notification.type)}>
          <h6
            style={{
              color: isRead ? "#6c757d" : "#FF902F",
            }}
          >
            {notification.title}
          </h6>
          <div
            style={{
              fontSize: 11,
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              color: "black",
            }}
          >
            {notification.description}
          </div>
        </Link>
        <ul className="nav">
          <li>{new Date(notification.time).toLocaleString()}</li>
        </ul>
      </div>
    </li>
  );
};

export default EmployeeDashboard;
