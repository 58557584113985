import React from "react";
import Header from "../../../../layout/Header";
import AdminSideBar from "../../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import TrainingVerificationList from "./TrainingVerificationList";

const TrainingVerification = () => {
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <TrainingVerificationList />
        </div>
      </div>
    </>
  );
};

export default TrainingVerification;
