import React from "react";
import ZIITopicList from "./ZIITopicList";
import Header from "../../../../layout/Header";
import AdminSideBar from "../../../MainPages/Dashboard/AdminDashboard/AdminSideBar";

const ZIITopic = () => {
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <div className="row mb-4">
            <div className="col-12">
              <h1 className="text-2xl font-bold">ZII Topics</h1>
            </div>
          </div>
          <ZIITopicList />
        </div>
      </div>
    </>
  );
};

export default ZIITopic;
