import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Modal,
  Form,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TechnicalSideBar from "../../../layout/TechnicalSideBar";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import Header from "../../../layout/Header";
import {
  projectManagement,
  employeeDetails,
  projects,
  employeeList,
} from "../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../helpers/axios/axios";

const TechProjectManagement = () => {
  const [projectsData, setProjectsData] = useState([]);
  const [employeeID, setEmployeeID] = useState(null);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [attachmentsModal, setAttachmentsModal] = useState(false);
  const [currentAttachments, setCurrentAttachments] = useState([]);
  const [newProjectData, setNewProjectData] = useState({
    project: "",
    job_status: "Open",
    station: "",
    description: "",
    end_date: "",
    due_date: "",
    new_attachments: [],
  });
  const [selectedProject, setSelectedProject] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchAssignTerm, setSearchAssignTerm] = useState("");
  const [filterStation, setFilterStation] = useState("");
  const [filterJobStatus, setFilterJobStatus] = useState("");
  const [viewMode, setViewMode] = useState("card"); // 'card' or 'list'
  const [technicalListNames, setTechnicalListNames] = useState([]);
  const [assigneeSearch, setAssigneeSearch] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [modalAssigneeSearch, setModalAssigneeSearch] = useState(""); // New state for modal
  const [assigneeID, setAssigneeID] = useState(null); // Separate state for assignee ID

  const fetchProjects = useCallback(async () => {
    try {
      let query = `${projectManagement}?`;
      if (searchTerm) {
        query += `project_name=${searchTerm}&`;
      }
      if (searchAssignTerm) {
        query += `assignee=${searchAssignTerm}&`;
      }
      if (filterStation) {
        query += `station=${filterStation}&`;
      }
      if (filterJobStatus) {
        query += `job_status=${filterJobStatus}&`;
      }

      const response = await axiosInstance.get(query);
      const projectsArray = Object.values(response.data); // Convert object to array
      console.log("Data", response.data);
      setProjectsData(projectsArray);
      const availableProjectsResponse = await axiosInstance.get(projects);
      setAvailableProjects(availableProjectsResponse.data);
    } catch (error) {
      console.error("Error fetching project list:", error);
    }
  }, [searchTerm, searchAssignTerm, filterStation, filterJobStatus]);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeID(response.data.employment_details[0].id);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const departmentIDs = [3, 7]; // Add more department IDs as needed
        let allEmployees = [];
        for (const id of departmentIDs) {
          const response = await axiosInstance.get(
            `${employeeList}?department=${id}`
          );
          allEmployees = [...allEmployees, ...response.data];
        }
        setTechnicalListNames(allEmployees);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDepartments();
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const handleShowModal = () => {
    setIsEditMode(false);
    setNewProjectData({
      project: "",
      job_status: "Open",
      station: "",
      description: "",
      end_date: "",
      due_date: "",
      new_attachments: [],
    });
    setModalAssigneeSearch(""); // Clear assignee search on modal open
    setFilteredEmployees([]);
    setAssigneeID(null); // Clear assignee ID on modal open
    setSelectedProject(null);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setAssigneeSearch(""); // Clear assignee search on modal close
    setFilteredEmployees([]);
    setAssigneeID(null); // Clear assignee ID on modal close
    setSearchAssignTerm("");
  };

  const handleFileChange = (e) => {
    setNewProjectData({
      ...newProjectData,
      new_attachments: [...e.target.files],
    });
  };

  const handleEditProject = (project) => {
    console.log("Editing project:", project); // Debugging
    console.log("Setting isEditMode to true and selectedProject:", project.id); // Debugging

    const assigneeName =
      technicalListNames?.find((employee) => employee.id === project.assignee)
        ?.employee_name || "";

    setSelectedProject(project); // Set the project to be edited
    setIsEditMode(true); // Set edit mode to true
    setNewProjectData({
      project: project.project_details.name, // Set the project name for display
      job_status: project.job_status,
      station: project.station,
      description: project.description,
      end_date: project.end_date,
      due_date: project.due_date,
    });
    setAssigneeID(project.assignee); // Set the assignee ID
    setModalAssigneeSearch(assigneeName); // Set the assignee name in the search input
    setShowModal(true);
  };

  const handleCreateProject = async () => {
    console.log("isEditMode:", isEditMode); // Should be true when editing
    console.log("selectedProject:", selectedProject);
    console.log("assigneeID before patching:", assigneeID);
    const selectedProjectId = availableProjects.find(
      (p) => p.name === newProjectData.project
    )?.id;

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const formData = new FormData();
    formData.append("owner", employeeID);
    //formData.append("assignee", assigneeID); // Using assigneeID state
    formData.append("project", selectedProjectId); // Use the project ID for submission
    formData.append("job_status", newProjectData.job_status);
    formData.append("station", newProjectData.station);
    formData.append("description", newProjectData.description);
    formData.append("end_date", newProjectData.end_date);
    formData.append("due_date", newProjectData.due_date);

    // Check if new_attachments exists and is an array before using forEach
    if (Array.isArray(newProjectData.new_attachments)) {
      newProjectData.new_attachments.forEach((file) => {
        formData.append("new_attachments", file);
      });
    }

    try {
      if (selectedProject && isEditMode) {
        // Ensure the check is done correctly
        await axiosInstance.patch(
          `${projectManagement}${selectedProject.id}/`,
          formData,
          config
        );
        toast.success("Project updated successfully!");
      } else {
        await axiosInstance.post(projectManagement, formData, config);
        toast.success("Project added successfully!");
        console.log("posted");
      }

      fetchProjects();
      handleCloseModal();
    } catch (error) {
      console.error("Error creating/updating project:", error);
      toast.error("Failed to create/update project");
    }
  };

  const handleDeleteProject = async (project) => {
    try {
      await axiosInstance.delete(`${projectManagement}${project.id}/`);
      toast.success("Project deleted successfully!");
      fetchProjects();
    } catch (error) {
      console.error("Error deleting project:", error);
      toast.error("Failed to delete project");
    }
  };

  const handleViewAttachments = (attachments) => {
    setCurrentAttachments(attachments);
    setAttachmentsModal(true);
  };

  const calculateProgress = (status) => {
    switch (status) {
      case "Open":
        return 0;
      case "Ongoing":
        return 50;
      case "Closed":
        return 100;
      default:
        return 0;
    }
  };

  const getProgressBarColor = (status) => {
    switch (status) {
      case "Open":
        return "bg-danger"; // Red for 0%
      case "Ongoing":
        return "bg-warning"; // Yellow for 50%
      case "Closed":
        return "bg-success"; // Green for 100%
      default:
        return "bg-secondary"; // Default color (optional)
    }
  };

  const getProgressTextColor = (status) => {
    switch (status) {
      case "Open":
        return "text-danger text-sm float-end"; // Red for 0%
      case "Ongoing":
        return "text-warning text-sm float-end"; // Yellow for 50%
      case "Closed":
        return "text-success text-sm float-end"; // Green for 100%
      default:
        return "text-secondary text-sm float-end"; // Default color (optional)
    }
  };

  const renderProjects = () => {
    if (viewMode === "card") {
      return (
        <div className="row">
          {projectsData.map((project) => (
            <div
              className="col-lg-4 col-sm-6 col-md-4 col-xl-3 d-flex"
              key={project.id}
            >
              <div className="card w-100">
                <div className="card-body">
                  <div className="dropdown dropdown-action profile-action">
                    <Link
                      to="#"
                      className="action-icon dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="material-icons">more_vert</i>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      {employeeID === project.owner ||
                      employeeID === project.assignee ? (
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={() => handleEditProject(project)}
                        >
                          <i className="fa fa-pencil m-r-5" /> Edit
                        </Link>
                      ) : (
                        <></>
                      )}
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={() => handleDeleteProject(project)}
                      >
                        <i className="fa fa-trash m-r-5" /> Delete
                      </Link>
                      {project.attachments &&
                        project.attachments.length > 0 && (
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() =>
                              handleViewAttachments(project.attachments)
                            }
                          >
                            <i className="fa fa-eye m-r-5" /> View Attachments
                          </Link>
                        )}
                    </div>
                  </div>
                  <h4 className="project-title">
                    <Link to="/projects-view">
                      {project.project_details.name}
                    </Link>
                  </h4>
                  <small className="block text-ellipsis m-b-15">
                    <span className="text-xs">
                      Status: {project.job_status}
                    </span>
                  </small>
                  <div className="sub-title">Description:</div>
                  <p className="text-muted">{project.description}</p>
                  <div className="pro-deadline m-b-15">
                    <div className="sub-title">Deadline:</div>
                    <div className="text-muted">{project.end_date}</div>
                  </div>
                  <div className="project-members m-b-15">
                    <div>Owner :</div>
                    <ul className="team-members">
                      <li>
                        <p className="p-0" title="Leader">
                          {technicalListNames?.find(
                            (employee) => employee.id === project.owner
                          )?.employee_name || "Unknown"}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="project-members m-b-15">
                    <div>Assigned To:</div>
                    <ul className="team-members">
                      <li>
                        <p className="p-0" title="Assignee">
                          {technicalListNames?.find(
                            (employee) => employee.id === project.assignee
                          )?.employee_name || "Unknown"}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <p className="m-b-5">
                    Progress
                    <span
                      className={`${getProgressTextColor(project.job_status)}`}
                    >
                      {calculateProgress(project.job_status)}%
                    </span>
                  </p>
                  <div className="progress progress-xs mb-0">
                    <div
                      className={`progress-bar ${getProgressBarColor(
                        project.job_status
                      )}`}
                      role="progressbar"
                      data-bs-toggle="tooltip"
                      title={`${calculateProgress(project.job_status)}%`}
                      style={{
                        width: `${calculateProgress(project.job_status)}%`,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="table-responsive">
          <table className="table table-striped custom-table">
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Project Name</th>
                <th>Status</th>
                <th>Description</th>
                <th>Deadline</th>
                <th>Owner</th>
                <th>Assigned To</th>
                <th>Progress</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {projectsData.map((project, index) => (
                <tr key={project.id}>
                  <td>{index + 1}</td>
                  <td>
                    <h2>
                      <Link to="/projects-view">
                        {project.project_details.name}
                      </Link>
                    </h2>
                  </td>
                  <td>{project.job_status}</td>
                  <p className="text-muted">{project.description}</p>
                  <td>{project.end_date}</td>
                  <td>
                    <p className="p-0" title="Leader">
                      {technicalListNames?.find(
                        (employee) => employee.id === project.owner
                      )?.employee_name || "Unknown"}
                    </p>
                  </td>
                  <td>
                    <p className="p-0" title="Assignee">
                      {technicalListNames?.find(
                        (employee) => employee.id === project.assignee
                      )?.employee_name || "Unknown"}
                    </p>
                  </td>
                  <td>
                    <div className="progress progress-xs">
                      <div
                        className={`progress-bar ${getProgressBarColor(
                          project.job_status
                        )}`}
                        role="progressbar"
                        data-bs-toggle="tooltip"
                        title={`${calculateProgress(project.job_status)}%`}
                        style={{
                          width: `${calculateProgress(project.job_status)}%`,
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="dropdown dropdown-action">
                      <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </Link>
                      <div className="dropdown-menu dropdown-menu-right">
                        {employeeID === project.owner ||
                        employeeID === project.assignee ? (
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => handleEditProject(project)}
                          >
                            <i className="fa fa-pencil m-r-5" /> Edit
                          </Link>
                        ) : (
                          <></>
                        )}
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={() => handleDeleteProject(project)}
                        >
                          <i className="fa fa-trash m-r-5" /> Delete
                        </Link>
                        {project.attachments &&
                          project.attachments.length > 0 && (
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() =>
                                handleViewAttachments(project.attachments)
                              }
                            >
                              <i className="fa fa-eye m-r-5" /> View Attachments
                            </Link>
                          )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  };

  const handleCloseAttachmentsModal = () => setAttachmentsModal(false);

  const renderAttachmentContent = (attachment, index) => {
    return (
      <div className="row mb-3" key={index}>
        <div className="col-12">
          <div className="card p-3 shadow-sm">
            <h6 className="mb-0">Attachment {index + 1}</h6>
            <div className="d-flex align-items-center">
              <p className="mb-0 me-3">
                Please click the link to view the attachment:
              </p>
              <a
                href={attachment.file}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary btn-sm"
              >
                View Attachment
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      <TechnicalSideBar />
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row mb-0 align-items-center">
              <div className="col-md-8">
                <Breadcrumbs
                  maintitle="Manage Projects"
                  title="Dashboard"
                  subtitle="Projects"
                  modal="#create_project"
                  name="Create Project"
                  Linkname="/projects"
                  Linkname1="/project-list"
                />
              </div>
              <div className="col-md-4 d-flex justify-content-end">
                <ToggleButtonGroup
                  type="radio"
                  name="viewMode"
                  value={viewMode}
                  onChange={(val) => setViewMode(val)}
                >
                  <ToggleButton
                    id="tbg-radio-1"
                    value="card"
                    variant={
                      viewMode === "card" ? "primary" : "outline-primary"
                    }
                  >
                    Card View
                  </ToggleButton>
                  <ToggleButton
                    id="tbg-radio-2"
                    value="list"
                    variant={
                      viewMode === "list" ? "primary" : "outline-primary"
                    }
                  >
                    List View
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12 d-flex align-items-center">
                <div className="col-md-3 me-2">
                  <div className="input-block mb-1 position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Project Name"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <i
                      className="fa fa-search position-absolute"
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    ></i>
                  </div>
                </div>
                {/* <div className="col-md-3 me-2">
                  <div className="input-block mb-1 position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Assigned To"
                      value={assigneeSearch}
                      onChange={handleAssigneeSearch}
                      style={{ zIndex: 1 }} // Input field z-index
                    />
                    <i
                      className="fa fa-search position-absolute"
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                      }}
                    ></i>
                    {filteredEmployees.length > 0 && (
                      <ul
                        className="list-group position-absolute w-100"
                        style={{
                          top: "100%", // Position below the input
                          zIndex: 1000, // Higher z-index to stay above other elements
                        }}
                      >
                        {filteredEmployees.map((employee) => (
                          <li
                            key={employee.id}
                            className="list-group-item list-group-item-action"
                            onClick={() => handleAssigneeSelect(employee)}
                          >
                            {employee.employee_name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div> */}

                <div className="d-flex">
                  <div className="col-md-6 me-2">
                    <Form.Select
                      className=""
                      value={filterStation}
                      onChange={(e) => setFilterStation(e.target.value)}
                    >
                      <option value="">Select Station</option>
                      <option value="UI Development">UI Development</option>
                      <option value="Backend">Backend</option>
                      <option value="Integration">Integration</option>
                      <option value="Deployment">Deployment</option>
                      <option value="Testing">Testing</option>
                      <option value="Security">Security</option>
                      <option value="Going Live">Going Live</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-6 me-2">
                    <Form.Select
                      className=""
                      value={filterJobStatus}
                      onChange={(e) => setFilterJobStatus(e.target.value)}
                    >
                      <option value="">Select Job Status</option>
                      <option value="Open">Open</option>
                      <option value="Ongoing">Ongoing</option>
                      <option value="Closed">Closed</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="ms-auto">
                  <Button className="btn btn-success" onClick={handleShowModal}>
                    Add Subtrack
                  </Button>
                </div>
              </div>
            </div>

            {projectsData.length === 0 ? (
              <div className="row">
                <div className="col-md-12 text-center mt-2">
                  <p>No Projects Found</p>
                  <div className="skeleton-loader">
                    <div className="card-body"></div>
                    <div className="skeleton-card"></div>
                    <div className="skeleton-card"></div>
                  </div>
                </div>
              </div>
            ) : (
              renderProjects()
            )}
          </div>
        </div>

        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditMode ? "Edit Project" : "Create Project"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="row">
                <Form.Group className="col-md-6" controlId="formProjectName">
                  <Form.Label>Select Project</Form.Label>
                  <Form.Control
                    as="select"
                    value={newProjectData.project}
                    onChange={(e) =>
                      setNewProjectData({
                        ...newProjectData,
                        project: e.target.value,
                      })
                    }
                  >
                    <option value="">Select a project</option>
                    {availableProjects.map((project) => (
                      <option key={project.id} value={project.name}>
                        {project.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="col-md-6" controlId="formJobStatus">
                  <Form.Label>Job Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={newProjectData.job_status}
                    onChange={(e) =>
                      setNewProjectData({
                        ...newProjectData,
                        job_status: e.target.value,
                      })
                    }
                  >
                    <option value="Open">Open</option>
                    <option value="Ongoing">Ongoing</option>
                    <option value="Closed">Closed</option>
                  </Form.Control>
                </Form.Group>
              </div>

              <div className="row mt-3">
                <Form.Group className="col-md-6" controlId="formStation">
                  <Form.Label>Station</Form.Label>
                  <Form.Control
                    as="select"
                    value={newProjectData.station}
                    onChange={(e) =>
                      setNewProjectData({
                        ...newProjectData,
                        station: e.target.value,
                      })
                    }
                  >
                    <option value="">Select a station</option>
                    <option value="UI Development">UI Development</option>
                    <option value="Backend">Backend</option>
                    <option value="Integration">Integration</option>
                    <option value="Deployment">Deployment</option>
                    <option value="Testing">Testing</option>
                    <option value="Security">Security</option>
                    <option value="Going Live">Going Live</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="col-md-6" controlId="formAttachments">
                  <Form.Label>Attachments</Form.Label>
                  <Form.Control
                    type="file"
                    multiple
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>

              <div className="row mt-3">
                <Form.Group className="col-md-6" controlId="formProjectEndDate">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={newProjectData.end_date}
                    onChange={(e) =>
                      setNewProjectData({
                        ...newProjectData,
                        end_date: e.target.value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group className="col-md-6" controlId="formProjectDueDate">
                  <Form.Label>Due Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={newProjectData.due_date}
                    onChange={(e) =>
                      setNewProjectData({
                        ...newProjectData,
                        due_date: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </div>

              <Form.Group
                className="col-md-12 mt-2"
                controlId="formProjectDescription"
              >
                <Form.Label>Project Description</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter project description"
                  value={newProjectData.description}
                  style={{ minHeight: "90px" }}
                  onChange={(e) =>
                    setNewProjectData({
                      ...newProjectData,
                      description: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleCreateProject}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={attachmentsModal}
          onHide={handleCloseAttachmentsModal}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>View Attachments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentAttachments.length > 0 ? (
              currentAttachments.map((attachment, index) => (
                <div key={index}>
                  {renderAttachmentContent(attachment, index)}
                </div>
              ))
            ) : (
              <p>No attachments available.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAttachmentsModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default TechProjectManagement;
