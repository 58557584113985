import React, { useState, useEffect, useCallback } from "react";
import { Table, Button } from "antd";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addLeaves,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const YourLeaves = () => {
  const [leaves, setLeaves] = useState([]);
  const [employeeID, setEmployeeID] = useState(null);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeID(response.data.employment_details[0].id);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const fetchLeaves = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `${addLeaves}?user=${employeeID}`
      );
      setLeaves(response.data);
      console.log("Leaves", response.data);
    } catch (error) {
      console.error("Error fetching leaves:", error);
      toast.error("Failed to fetch leaves");
    }
  }, [employeeID]);

  useEffect(() => {
    if (employeeID) {
      fetchLeaves();
    }
  }, [employeeID, fetchLeaves]);

  const handleViewLeave = async (leave) => {
    setSelectedLeave(leave);

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedLeave(null);
  };

  const handleViewDocument = (documentUrl) => {
    window.open(documentUrl, "_blank");
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (date) => moment(date).format("DD-MM-YYYY"), // Changed to DD-MM-YYYY format
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (date) => moment(date).format("DD-MM-YYYY"), // Changed to DD-MM-YYYY format
    },
    {
      title: "Total Days",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        let statusText = "Pending";
        let backgroundColor = "#ffc107";

        if (record.is_approved && !record.is_pending) {
          statusText = "Approved";
          backgroundColor = "#4caf50";
        }

        return (
          <span
            style={{
              padding: "10px",
              borderRadius: "5px",
              color: "#fff",
              backgroundColor,
            }}
          >
            {statusText}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleViewLeave(record)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="content-page-header text-center">
            <h2 className="text-3xl font-bold text-gray-700">Your Leaves</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body mt-0">
                <div className="leave-list-table">
                  <Table
                    dataSource={leaves}
                    columns={columns}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                    bordered
                    className="table-striped"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Leave Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedLeave && (
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="subject">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        readOnly
                        value={selectedLeave.subject}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="leaveType" className="mt-2">
                      <Form.Label>Leave Type</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={selectedLeave.leave_type}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="startDate" className="mt-2">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={moment(selectedLeave.start_date).format(
                          "DD-MM-YYYY"
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="endDate" className="mt-2">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={moment(selectedLeave.end_date).format(
                          "DD-MM-YYYY"
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="totalDays" className="mt-2">
                      <Form.Label>Total Days</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={selectedLeave.total}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="halfDaySpec" className="mt-2">
                      <Form.Label>Half Day Specification</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={
                          selectedLeave.leave_specification
                            ? selectedLeave.leave_specification
                            : "None selected"
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="reason" className="mt-2">
                  <Form.Label>Reason</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    readOnly
                    value={selectedLeave.reason}
                    style={{ resize: "none" }}
                  />
                </Form.Group>
                <Row>
                  <Form.Group controlId="status" className="mt-2 col-md-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly
                      value={selectedLeave.is_approved ? "Approved" : "Pending"}
                    />
                  </Form.Group>
                  <Form.Group controlId="approvedBy" className="mt-2 col-md-3">
                    <Form.Label>Approved By</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly
                      value={
                        selectedLeave.approved_details?.employee_name || "N/A"
                      }
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="contactPerson"
                    className="mt-2 col-md-3"
                  >
                    <Form.Label>Contact Person</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly
                      value={
                        selectedLeave.contact_details?.employee_name || "N/A"
                      }
                    />
                  </Form.Group>
                </Row>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            {selectedLeave?.document && (
              <Button
                key="view-doc"
                variant="primary"
                onClick={() => handleViewDocument(selectedLeave.document)}
              >
                View Document
              </Button>
            )}
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default YourLeaves;
