export const RSsidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Employee Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/employee-dashboard",
        icon: "la la-dashcube",
      },
      {
        menuValue: "Documents",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-file-text",
        subMenus: [
          {
            menuValue: "Add Document",

            route: "/file-upload",
          },
          {
            menuValue: "View Documents",

            route: "/view-documents",
          },
          {
            menuValue: "Document Naming",

            route: "/document-naming",
          },
        ],
      },
      {
        menuValue: "ZII",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-list",
        subMenus: [
          {
            menuValue: "Add ZII",
            route: "/add-zii",
          },
          {
            menuValue: "Your ZII",
            route: "/employee-zii",
          },
        ],
      },

      {
        menuValue: "Approvals",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-check-square",
        subMenus: [
          {
            menuValue: "Training Course Approval",
            route: "/training-approval",
          },
          {
            menuValue: "Policy Approval",
            route: "/policy-approval",
          },
        ],
      },
      {
        menuValue: "Courses",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-graduation-cap",
        subMenus: [
          {
            menuValue: "Your Courses",
            route: "/employee-courses",
          },
        ],
      },
      {
        menuValue: "Policies",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-file-pdf-o",
        subMenus: [
          {
            menuValue: "View Policies",
            route: "/employee-policy",
          },
        ],
      },
      {
        menuValue: "Appraisal",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-star-o",
        subMenus: [
          {
            menuValue: "Weekly Appraisal",
            route: "/employee-appraisal",
          },
          {
            menuValue: "My Appraisal",
            route: "/appraisal-table",
          },
          {
            menuValue: "Department Appraisal Report",
            route: "/appraisal-report-alldept",
          },
        ],
      },
      {
        menuValue: "Leaves",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-calendar",
        subMenus: [
          {
            menuValue: "Leave Form",
            route: "/leaves-form",
          },
          {
            menuValue: "My Leaves",
            route: "/your-leaves",
          },
          {
            menuValue: "Approve Leaves",
            route: "/approve-leaves",
          },
        ],
      },
    ],
  },
];
