import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../layout/Header";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  employeeDetails,
  addAppraisal,
  employeeList,
  companyAveragebyWeek,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmployeeAppraisal = () => {
  const [activeYear, setActiveYear] = useState(new Date().getFullYear());
  const [activeMonthButton, setActiveMonthButton] = useState(
    new Date().getUTCMonth() + 1
  );
  const [activeWeekButton, setActiveWeekButton] = useState(1);
  const [supervisorId, setSupervisorId] = useState(null);
  const [reporteeID, setReporteeID] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [noEmployeesAssigned, setNoEmployeesAssigned] = useState(false);
  const [option1, setOption1] = useState("");
  const [comment, setComment] = useState("");
  const [option2, setOption2] = useState("");

  const [option3, setOption3] = useState("");

  const [option4, setOption4] = useState("");

  const [average, setAverage] = useState(0);
  const [total, setTotal] = useState(0);
  const [companyAverage, setCompanyAverage] = useState(0);

  const [isReadOnly, setIsReadOnly] = useState(false);

  useEffect(() => {
    axiosInstance.get(employeeDetails).then(
      (response) => {
        const supervisor = response.data.employment_details[0].id;
        setSupervisorId(supervisor);

        axiosInstance
          .get(`${employeeList}?reporting_to=${supervisor}`)
          .then((res) => {
            if (res.data && res.data.length > 0) {
              setEmployees(res.data);
              setNoEmployeesAssigned(false);
            } else {
              setEmployees([]);
              setNoEmployeesAssigned(true);
            }
          })
          .catch((err) => {
            console.log("Error fetching employees:", err);
            setNoEmployeesAssigned(true);
          });
      },
      (error) => {
        console.log(error);
        setNoEmployeesAssigned(true);
      }
    );
  }, []);

  const fetchAppraisalData = useCallback(() => {
    if (supervisorId && reporteeID) {
      const monthName = new Date(Date.UTC(2020, activeMonthButton - 1))
        .toLocaleString("default", { month: "long" })
        .toLowerCase();
      const weekNumber = `Week ${activeWeekButton}`;

      axiosInstance
        .get(
          addAppraisal(
            reporteeID,
            supervisorId,
            activeYear,
            monthName,
            weekNumber
          )
        )
        .then((response) => {
          if (response.data && response.data[0]) {
            setOption1(response.data[0].option1 || "");
            setOption2(response.data[0].option2 || "");
            setOption3(response.data[0].option3 || "");
            setOption4(response.data[0].option4 || "");
            setComment(response.data[0].comments || "");
            setIsReadOnly(true); // Set to read-only if data exists
          } else {
            setOption1("");
            setOption2("");
            setOption3("");
            setOption4("");
            setComment("");
            setIsReadOnly(false); // Set to editable if no data exists
          }
        })
        .catch((err) => {
          console.log(err);
          setOption1("");
          setOption2("");
          setOption3("");
          setOption4("");
          setComment("");
          setIsReadOnly(false); // Set to editable if there's an error
        });

      // API call to companyAveragebyWeek
      axiosInstance
        .get(companyAveragebyWeek(activeWeekButton, activeYear, monthName))
        .then((response) => {
          const compAverage = response.data?.company_average || 0;
          setCompanyAverage(compAverage);
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.message ===
              "No data found for the given week, month, and year."
          ) {
            setCompanyAverage(0);
          } else {
            console.log("Error fetching company averages", err);
            setCompanyAverage(0);
          }
        });
    }
  }, [
    supervisorId,
    reporteeID,
    activeYear,
    activeMonthButton,
    activeWeekButton,
  ]);

  useEffect(() => {
    fetchAppraisalData();
  }, [fetchAppraisalData]);

  useEffect(() => {
    const calculateAverage = () => {
      const num1 = parseFloat(option1) || 0;
      const num2 = parseFloat(option2) || 0;
      const num3 = parseFloat(option3) || 0;
      const num4 = parseFloat(option4) || 0;
      const avg = (num1 + num2 + num3 + num4) / 4;
      return avg.toFixed(2);
    };
    const calculateTotal = () => {
      const num1 = parseFloat(option1) || 0;
      const num2 = parseFloat(option2) || 0;
      const num3 = parseFloat(option3) || 0;
      const num4 = parseFloat(option4) || 0;
      const tt = num1 + num2 + num3 + num4;
      return tt;
    };
    setAverage(calculateAverage());
    setTotal(calculateTotal());
  }, [option1, option2, option3, option4]);

  const handleWeekClick = (weekNumber) => {
    setActiveWeekButton(weekNumber);
  };

  const handleMonthClick = (monthNumber) => {
    setActiveMonthButton(monthNumber);
    setActiveWeekButton(1); // Reset to the first week of the selected month
  };

  const handleYearChange = (event) => {
    setActiveYear(event.target.value);
  };

  const handleEmployeeClick = (id) => {
    setReporteeID(id);
  };

  const handleSubmit = () => {
    if (isReadOnly) {
      toast.info(
        "This appraisal has already been submitted and cannot be edited."
      );
      return;
    }

    const monthName = new Date(Date.UTC(2020, activeMonthButton - 1))
      .toLocaleString("default", { month: "long" })
      .toLowerCase();
    const weekNumber = `Week ${activeWeekButton}`;

    const payload = {
      reportee: reporteeID,
      supervisor: supervisorId,
      year: activeYear,
      month: monthName,
      week: weekNumber,
      option1: option1 || null,
      option2: option2 || null,
      option3: option3 || null,
      option4: option4 || null,
      comments: comment || null,
    };

    axiosInstance
      .post(
        addAppraisal(
          reporteeID,
          supervisorId,
          activeYear,
          monthName,
          weekNumber
        ),
        payload
      )
      .then((response) => {
        toast.success("Appraisal data submitted successfully!");
        setIsReadOnly(true); // Set to read-only after successful submission
        fetchAppraisalData(); // Refresh data after submission
      })
      .catch((err) => {
        console.log("Error submitting appraisal data", err);
        toast.error("Failed to submit appraisal data.");
      });
  };

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <div className="row mb-2">
            <div className="col-12 d-flex justify-content-between">
              <h1 className="text-2xl font-bold">Weekly Appraisals</h1>
              <select
                value={activeYear}
                onChange={handleYearChange}
                className="form-select"
                style={{ width: "150px" }}
              >
                {Array.from({ length: 5 }, (_, i) => activeYear - i).map(
                  (year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>

          <div className="tabs mb-1">
            {Array.from({ length: 12 }, (_, i) => i + 1).map((monthNumber) => (
              <div
                key={monthNumber}
                className={`tab-item ${
                  activeMonthButton === monthNumber ? "active" : ""
                }`}
                onClick={() => handleMonthClick(monthNumber)}
              >
                {new Date(Date.UTC(2020, monthNumber - 1)).toLocaleString(
                  "default",
                  { month: "long" }
                )}
              </div>
            ))}
          </div>
          <div className="btn mb-1">
            {[1, 2, 3, 4, 5].map((weekNumber) => (
              <button
                key={weekNumber}
                type="button"
                className={`btn mx-1 ${
                  activeWeekButton === weekNumber
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                onClick={() => handleWeekClick(weekNumber)}
              >
                Week-{weekNumber}
              </button>
            ))}
          </div>
          {noEmployeesAssigned ? (
            <div className="alert alert-info" role="alert">
              Nobody is assigned to you for Appraisal at this time.
            </div>
          ) : (
            <>
              <ul className="nav nav-tabs mt-1" id="employeeTab" role="tablist">
                {employees.map((employee, index) => (
                  <li className="nav-item" key={employee.id}>
                    <a
                      className={`nav-link ${
                        reporteeID === employee.id ? "active" : ""
                      }`}
                      id={`employee-tab-${index}`}
                      data-toggle="tab"
                      href={`#employee-${index}`}
                      role="tab"
                      aria-controls={`employee-${index}`}
                      aria-selected={reporteeID === employee.id}
                      onClick={() => handleEmployeeClick(employee.id)}
                    >
                      {employee.employee_name}
                    </a>
                  </li>
                ))}
              </ul>

              <div className="tab-content" id="employeeTabContent">
                {employees.map((employee, index) => (
                  <div
                    className={`tab-pane fade ${
                      reporteeID === employee.id ? "show active" : ""
                    }`}
                    id={`employee-${index}`}
                    role="tabpanel"
                    aria-labelledby={`employee-tab-${index}`}
                    key={employee.id}
                  >
                    <div className="appraisal-form p-4 bg-light rounded shadow-sm">
                      <div className="form-group row align-items-center mb-3">
                        <label
                          htmlFor="option1"
                          className="col-sm-8 col-form-label"
                        >
                          Percentage or number of the Actions in DCDF data
                          movement in the team track that are being automated:
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            id="option1"
                            className="form-control"
                            value={option1}
                            onChange={(e) => setOption1(e.target.value)}
                            placeholder="Enter number"
                            readOnly={isReadOnly}
                          />
                        </div>
                      </div>
                      <div className="form-group row align-items-center mb-3">
                        <label
                          htmlFor="option2"
                          className="col-sm-8 col-form-label"
                        >
                          Percentage of own tasks [Automated] DCDF tracks
                          covering their own area [100% automation track for all
                          of the person's job]:
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            id="option2"
                            className="form-control"
                            value={option2}
                            onChange={(e) => setOption2(e.target.value)}
                            placeholder="Enter number"
                            readOnly={isReadOnly}
                          />
                        </div>
                      </div>
                      <div className="form-group row align-items-center mb-3">
                        <label
                          htmlFor="option3"
                          className="col-sm-8 col-form-label"
                        >
                          Percentage of the friction points covered in dataflow
                          actions:
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            id="option3"
                            className="form-control"
                            value={option3}
                            onChange={(e) => setOption3(e.target.value)}
                            placeholder="Enter number"
                            readOnly={isReadOnly}
                          />
                        </div>
                      </div>
                      <div className="form-group row align-items-center mb-3">
                        <label
                          htmlFor="option4"
                          className="col-sm-8 col-form-label"
                        >
                          Number of stakeholder specifications met in own team
                          area:
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            id="option4"
                            className="form-control"
                            value={option4}
                            onChange={(e) => setOption4(e.target.value)}
                            placeholder="Enter number"
                            readOnly={isReadOnly}
                          />
                        </div>
                      </div>

                      {/* Comment Field Below All Options */}
                      <div className="form-group row align-items-center mb-3">
                        <label
                          htmlFor="comment"
                          className="col-sm-8 col-form-label"
                        >
                          Comments:
                        </label>
                        <div className="col-md-12">
                          <textarea
                            id="comment"
                            className="form-control"
                            placeholder="Enter comments"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            readOnly={isReadOnly}
                          ></textarea>
                        </div>
                      </div>
                      <div className="grid content-start text-left">
                        <h4 className="mt-4">
                          Total [sum of all inputs]: {total}
                        </h4>
                        <h4 className="mt-0">Average: {average}</h4>
                        <h4 className="mt-0">
                          Company's Average: {companyAverage}
                        </h4>
                      </div>
                      <div className="text-center mt-3">
                        <button
                          className="btn btn-primary"
                          onClick={handleSubmit}
                          disabled={isReadOnly}
                        >
                          {isReadOnly
                            ? "Appraisal Submitted"
                            : "Submit Appraisal"}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <style jsx>{`
        .tabs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          border-bottom: 2px solid #ddd;
          overflow-x: auto;
        }
        .tab-item {
          cursor: pointer;
          padding: 10px 15px;
          color: gray;
          white-space: nowrap;
        }
        .tab-item.active {
          color: #007bff;
          font-weight: bold;
          border-bottom: 3px solid #007bff;
        }
        @media (max-width: 768px) {
          .tabs {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 5px;
          }
          .tab-item {
            text-align: center;
          }
        }
        .list-group-item.active {
          background-color: #007bff;
          color: white;
        }
        .list-group-item {
          cursor: pointer;
        }
        .nav-tabs .nav-link.active {
          background-color: #007bff;
          color: white;
          border-color: #007bff #007bff #007bff;
        }
        .nav-tabs .nav-link {
          color: #007bff;
          background-color: #ffffff;
        }
      `}</style>
    </>
  );
};

export default EmployeeAppraisal;
