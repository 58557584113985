import React, { useState, useEffect } from "react";
import Header from "../../../layout/Header";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { addLeaves } from "../../../../helpers/endpoints/api_endpoints";
import { Table } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmployeeOnLeaves = () => {
  const [employeeLeaves, setEmployeeLeaves] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch the list of employees who are on leave
  const fetchEmployeeLeaves = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${addLeaves}?is_approved=true`);
      console.log(response.data);
      setEmployeeLeaves(response.data);
    } catch (error) {
      console.error("Error fetching employee leaves:", error);
      toast.error("Failed to fetch employee leaves.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployeeLeaves();
  }, []);

  // Define columns for the Ant Design table
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Employee Name",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
    },
    {
      title: "Department",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "department_detail",
        "name",
      ],
      key: "department",
    },
    {
      title: "Designation",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "designation_detail",
        "name",
      ],
      key: "designation",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Contact",
      dataIndex: ["contact_details", "employee_name"],
      key: "contact_person",
      render: (text) => text || "N/A",
    },
  ];

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <h2 className="text-2xl font-bold mb-4">
            Employees Currently on Leave
          </h2>
          <div className="table-responsive">
            <Table
              dataSource={employeeLeaves}
              columns={columns}
              rowKey="id"
              loading={loading}
              pagination={{ pageSize: 10 }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeOnLeaves;
