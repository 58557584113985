import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import SearchBox from "../../../../components/SearchBox";
import TechnicalSideBar from "../../../layout/TechnicalSideBar";
import { Button, Modal, Form } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { projects } from "../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../helpers/axios/axios";
import Header from "../../../layout/Header";

const TechProjectList = () => {
  const [projectList, setProjectList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get(projects);
      setProjectList(response.data);
      console.log("Response project", response.data);
    } catch (error) {
      console.error("Error fetching project list:", error);
    }
  };

  const handleShowModal = () => {
    setIsEditMode(false);
    setNewProjectName("");
    setSelectedProject(null);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleCreateProject = async () => {
    try {
      if (isEditMode && selectedProject) {
        await axiosInstance.patch(`${projects}${selectedProject.id}/`, {
          name: newProjectName,
        });
        toast.success("Project updated successfully!");
      } else {
        await axiosInstance.post(projects, { name: newProjectName });
        toast.success("Project added successfully!");
      }

      // Refresh the project list after adding or editing a project
      fetchProjects();
      setNewProjectName("");
      handleCloseModal();
    } catch (error) {
      console.error("Error creating/updating project:", error);
    }
  };

  const handleEditProject = (record) => {
    setSelectedProject(record);
    setNewProjectName(record.name);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleDeleteProject = async (record) => {
    try {
      await axiosInstance.delete(`${projects}${record.id}/`);
      toast.success("Project deleted successfully!");
      fetchProjects();
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  // Define columns for the table
  const columns = [
    {
      title: "S.No",
      dataIndex: "serial_number",
      key: "serial_number",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Project Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Created",
      dataIndex: "created",
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
    {
      title: "Action",
      className: "text-end",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => handleEditProject(record)}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => handleDeleteProject(record)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      width: "10%",
    },
  ];

  return (
    <>
      <Header />
      <TechnicalSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Projects"
            title="Dashboard"
            subtitle="Projects"
            modal="#create_project"
            name="Add Project"
            Linkname="/projects"
            Linkname1="/project-list"
          />

          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12 d-flex justify-content-between align-items-center mb-2">
              <SearchBox />
              <button
                type="button"
                className="btn mx-1 btn-success"
                onClick={handleShowModal}
              >
                Add Project
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={projectList}
                  className="table table-striped custom-table"
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for adding/editing a project */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditMode ? "Edit Project" : "Add New Project"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formProjectName">
              <Form.Label>Project Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter project name"
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleCreateProject}
            disabled={isEditMode && newProjectName === selectedProject?.name}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Toast notifications */}
    </>
  );
};

export default TechProjectList;
