import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { OperationsideData } from "./OperationsideData";
import Scrollbars from "react-custom-scrollbars-2";

const OperationSideBar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { t } = useTranslation();

  const [sidebarData, setSidebarData] = useState(OperationsideData);
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const [isMouseOverSidebar, setMouseOverSidebar] = useState(false);
  const [submenuDrop, setSubmenuDrop] = useState(false);
  const [isSideMenu, setSideMenu] = useState("");
  const [level2Menu, setLevel2Menu] = useState("");
  const [level3Menu, setLevel3Menu] = useState("");
  const [isSideMenunew, setSideMenuNew] = useState("dashboard");

  useEffect(() => {
    if (
      isMouseOverSidebar &&
      document.body.classList.contains("mini-sidebar")
    ) {
      document.body.classList.add("expand-menu");
      return;
    }
    document.body.classList.remove("expand-menu");
  }, [isMouseOverSidebar]);

  const handleMouseEnter = () => {
    setMouseOverSidebar(true);
  };

  const handleMouseLeave = () => {
    setMouseOverSidebar(false);
  };

  const expandSubMenus = (menu) => {
    const updatedAdminSidebar = sidebarData.map((section) => {
      const updatedSection = { ...section };
      if (section.menu) {
        const updatedMenu = section.menu.map((menuItem) =>
          menu.menuValue !== menuItem.menuValue
            ? { ...menuItem, showSubRoute: false }
            : { ...menuItem, showSubRoute: !menuItem.showSubRoute }
        );
        updatedSection.menu = updatedMenu;
      }
      return section.title === menu.title ? updatedSection : section;
    });
    setSidebarData(updatedAdminSidebar);
  };

  const arrowDrop = () => {
    setSubmenuDrop(!submenuDrop);
  };

  const toggleSidebar = (value) => {
    setSideMenu(value);
    setSideMenuNew(value);
  };

  const toggleLvelTwo = (value) => {
    setLevel2Menu(value);
  };

  const toggleLevelThree = (value) => {
    setLevel3Menu(value);
  };

  return (
    <div
      className={`sidebar ${isSidebarExpanded ? "" : "hidden"}`}
      id="sidebar"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="sidebar-inner slimscroll" style={{ overflow: false }}>
        <div id="sidebar-menu" className="sidebar-menu">
          <Scrollbars
            autoHide={false}
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={0}
            autoHeightMax="100vh"
            thumbMinSize={30}
            universal={false}
            hideTracksWhenNotNeeded={true}
          >
            <ul
              className="sidebar-vertical"
              id="veritical-sidebar"
              style={{ display: "block" }}
            >
              {sidebarData.map((mainTittle, index) => (
                <React.Fragment key={index + 1}>
                  <li className="menu-title">
                    <span>{t(mainTittle.tittle)}</span>
                    {mainTittle?.tittle === "CRM" ? (
                      <small className="newly-added-features">New</small>
                    ) : (
                      ""
                    )}
                  </li>
                  {mainTittle.menu.map((menu, menuIndex) => (
                    <React.Fragment key={menuIndex + 1}>
                      {menu.hasSubRoute === false ? (
                        <li className={pathname === menu.route ? "active" : ""}>
                          <Link to={menu.route}>
                            <i className={menu.icon} />
                            <span>{t(menu.menuValue)}</span>
                          </Link>
                        </li>
                      ) : (
                        <li className="submenu">
                          <Link
                            to="#"
                            onClick={() => expandSubMenus(menu)}
                            className={menu.showSubRoute ? "subdrop" : ""}
                          >
                            <i className={menu.icon} />
                            <span
                              className={
                                menu.menuValue === "Employees" ? "noti-dot" : ""
                              }
                            >
                              {t(menu.menuValue)}
                            </span>
                            <span className="menu-arrow"></span>
                          </Link>
                          <ul
                            style={{
                              display: menu.showSubRoute ? "block" : "none",
                            }}
                          >
                            {menu.subMenus?.map((subMenus, subMenu) => (
                              <React.Fragment key={subMenu + 1}>
                                {subMenus.showMenuRoute === true ? (
                                  <li>
                                    <Link
                                      to={subMenus.route}
                                      className={submenuDrop ? "subdrop" : ""}
                                      onClick={arrowDrop}
                                    >
                                      {t(subMenus.menuValue)}
                                      <span className="menu-arrow"></span>
                                    </Link>
                                    <ul
                                      style={{
                                        display: submenuDrop ? "block" : "none",
                                      }}
                                    >
                                      {subMenus.subMenusValues?.map(
                                        (value, index) => (
                                          <li key={index}>
                                            <span>
                                              <Link to={value.route}>
                                                <span>
                                                  {t(value.menuValue)}
                                                </span>
                                              </Link>
                                            </span>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </li>
                                ) : (
                                  <li>
                                    <Link
                                      to={subMenus.route}
                                      className={
                                        pathname === subMenus.route
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      {t(subMenus.menuValue)}
                                    </Link>
                                    <ul>
                                      {subMenus.subMenusValues?.map(
                                        (value, index) => (
                                          <li key={index}>
                                            <Link
                                              to={value.route}
                                              className={
                                                pathname === value.route
                                                  ? "active"
                                                  : ""
                                              }
                                            >
                                              {t(value.menuValue)}
                                            </Link>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </li>
                                )}
                              </React.Fragment>
                            ))}
                          </ul>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </ul>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default OperationSideBar;
